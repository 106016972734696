import { subscriptionTiers } from '../constants';
import {
  FETCH_BILLING_STATUS,
  FETCH_MIGRATION_STATUS,
  setBillingStatus,
  setUpgradeModalVisibility,
  setError,
  setMigrationModalVisibility,
  setMerchant,
  setMixpanelDataFetched,
} from '../modules/core';
import { fetch } from '../utils/api';

export const fetchBillingStatus = () => fetch('/billingInfo');
export const fetchMigrationStatus = () => fetch('/migrationStatus');

export const fetchBillingStatusWithDispatch = async (dispatch) => {
  try {
    const billing = await fetchBillingStatus();
    const { appSubscription, merchant } = billing;
    // Eventually, we might have multiple paid tiers. For now, we just want
    // to ensure that they are paying subscribers.
    // Also, we don't want the upsell modal to show up in dev environment
    // and get in the way of development, so set to paid tier for now
    // when the app is running in dev mode.
    if (appSubscription.amount > 0) {
      dispatch(setBillingStatus(subscriptionTiers.PAID));
    } else {
      dispatch(setBillingStatus(subscriptionTiers.FREE));
      dispatch(setUpgradeModalVisibility(true));
    }
    dispatch(setMerchant(merchant));
    dispatch(setMixpanelDataFetched());
  } catch (err) {
    dispatch(setError('Billing status encountered an error:', err));
  }
};

export const fetchMigrationStatusWithDispatch = async (dispatch) => {
  try {
    const status = await fetchMigrationStatus();
    const { hasMigrationCompleted } = status;
    dispatch(setMigrationModalVisibility(!hasMigrationCompleted));
  } catch (err) {
    console.error('Error fetching Migration Status.');
    dispatch(setError('Error fetching Migration Status.', err));
  }
};

const CoreMiddleware = ({ dispatch }) => next => (action) => {
  switch (action.type) {
    case FETCH_BILLING_STATUS: {
      fetchBillingStatusWithDispatch(dispatch);
      break;
    }
    case FETCH_MIGRATION_STATUS: {
      fetchMigrationStatusWithDispatch(dispatch);
      break;
    }
    default:
  }
  return next(action);
};


export default CoreMiddleware;
