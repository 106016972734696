import { isDesktop } from '../../../utils/environment';

const handleOverflow = (str, maxLength = 8) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength - 1).trim()}...`;
  }
  return str;
};

const maxLabelLengthFromScreenSize = screenSize => (isDesktop(screenSize) ? 11 : 8);
const maxTooltipLengthFromScreenSize = screenSize => (isDesktop(screenSize) ? 25 : 13);

const labelsMargin = alignment => (alignment === 'left'
  ? '0px 0px 0px -55px' : '0px -55px 0px 0px');

export {
  // eslint-disable-next-line
  handleOverflow,
  labelsMargin,
  maxLabelLengthFromScreenSize,
  maxTooltipLengthFromScreenSize,
};
