import React, { useEffect } from 'react';

export const AfterLogin = () => {
  useEffect(() => {
    const token = window.location.href.split('?')[1];
    if (token) {
      window.localStorage.setItem('access-token', token);
    }
    window.location.href = window.location.origin;
  }, []);

  return <div/>;
};
