import axiosBase from 'axios';
import { API_HOST } from '../constants';
import { redirectToCloverAuth } from './authentication';

const axios = axiosBase.create({});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('access-token');
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  config => config,
  (err) => {
    if (err.response.status === 401) redirectToCloverAuth();
  },
);

/**
 * Generic function to send an HTTP GET request to the API
 * @param {string} url - the API url (e.g. 'orders')
 * @param {object} params - optional params (e.g. date range)
 */
export const fetch = (url, params = {}) => {
  const host = API_HOST || 'http://localhost:8000/api';
  // Allows `url` to look like either 'api/orders' or '/api/orders'
  const endpoint = `${host}${url.startsWith('/') ? '' : '/'}${url}`;
  const options = { params, withCredentials: true };

  return axios.get(endpoint, options).then(res => res.data);
};

export const fetchOrders = (filters = {}) => fetch('/orders', { ...filters, pick: ['timestamp', 'total', 'customer', 'employeeName'] });

export default {
  fetch,
};
