import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';
import {
  DESKTOP, TABLET, MOBILE, isMobile,
} from '../../../utils/environment';
import { formattedOrderTotal } from '../../../helpers/dateRange';
import DesktopVersion from './DesktopVersion';
import MobileVersion from './MobileVersion';
import OrderService from '../../../utils/order.service';
import Mixpanel from '../../../utils/mixpanel';

export const Row = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: flex-start;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: ${styles.colors.black};
  background-color: ${styles.colors.darkBlue};
`;

export const TabWrapper = styled.div`
  justify-content: center;
`;
export const Container = styled.div`
  padding-bottom: 8px;
`;

export const ExpandedContent = styled.div`
  background-color: ${styles.colors.lighterGray}; 
`;

export const ExpandedStats = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
`;

export const ItemName = styled.span`
  margin-bottom: 10px;
  font-weight: ${styles.typography.weights.light};
  color: ${styles.colors.whiteOpacity};
  font-size: ${styles.typography.sizes.medium}px;
`;

export const ItemValue = styled.span`
  padding-right: 15px;
  font-size: ${styles.typography.sizes.small}px;
  color: ${styles.colors.lightGray};
`;

export const LinearBar = styled.span`
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PieChartRank = styled.div`
  font-size: ${styles.typography.sizes.mediumLarger}px;
  font-weight: ${styles.typography.weights.light};
  color: ${styles.colors.lightGray};
  padding: 5px 5px 5px 20px;
`;

export const PieChartName = styled.span`
  flex: 1 1 auto;
  font-weight: ${styles.typography.weights.medium};
  color: ${styles.colors.white};
  font-size: ${styles.typography.sizes.small}px;
  padding: 5px;
`;

export const Rank = styled.div`
  flex: 0 1 ${props => (isMobile(props.screenSize) ? 20 : 12)}%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${styles.typography.sizes.huge}px;
  font-weight: ${styles.typography.weights.light};
  padding: 0 20px; 
`;

export const Stats = styled.div`
  flex: 0 1 ${props => (isMobile(props.screenSize) ? 60 : 45)}%;
  height: 100%;
  background-color: ${props => (props.selected
    ? styles.colors.lighterGray
    : styles.colors.darkBlue)};
`;

export const SaleOverTime = styled.div`
  flex: 0 1 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.selected
    ? styles.colors.lighterGray
    : styles.colors.darkBlue)};
`;

export const BreakDown = styled.div`
  flex: 0 1 18%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.selected
    ? styles.colors.lighterGray
    : styles.colors.darkBlue)};
`;

export const TooltipContainer = styled.div`
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100px;
`;

export const PieChartContainer = styled.div`
  display: flex;
  flex: 2 1 auto;
  justify-content: center;
  align-items: center;
`;

class CollapsiblePanel extends React.Component {
  static propTypes = {
    barColor: PropTypes.string,
    barChartData: PropTypes.instanceOf(Object).isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    itemName: PropTypes.string,
    itemValue: PropTypes.number,
    maxValue: PropTypes.number,
    pieChartData: PropTypes.instanceOf(Object).isRequired,
    rangeFilter: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired,
    screenSize: PropTypes.string.isRequired,
    setSelectedRowId: PropTypes.func.isRequired,
    statsData: PropTypes.instanceOf(Object).isRequired,
    mixpanelSectionName: PropTypes.string.isRequired,
    mixpanelFirstSectionEvent: PropTypes.string.isRequired,
    mixpanelSecondSectionEvent: PropTypes.string.isRequired,
    mixpanelThirdSectionEvent: PropTypes.string.isRequired
  };


  static getDerivedStateFromProps(props) {
    if (props.rank !== props.selectedRowId) {
      return {
        statsExpand: false,
        saleOverTimeExpand: false,
        breakDownExpand: false,
        tabValue: 0,
      };
    }
    return null;
  }

  state = {
    statsExpand: false,
    saleOverTimeExpand: false,
    breakDownExpand: false,
    tabValue: 0,
  };

  truncateString = (string, screenSize) => {
    let length = 20;

    switch (screenSize) {
      case DESKTOP: {
        length = 20;
        break;
      }
      case TABLET: {
        length = 15;
        break;
      }
      case MOBILE: {
        length = 30;
        break;
      }
      default: {
        return `${string.substring(0, length)}...`;
      }
    }

    //  Why length + 3? The ellipses is 3 characters long.
    if (string.length < (length + 3)) {
      return string;
    }

    return `${string.substring(0, length)}...`;
  };

  generateEntry = (data) => {
    const { screenSize } = this.props;
    const [rank, name, value] = data;
    return (
      <TooltipWrapper key={rank}>
        <PieChartRank>
          {rank + 1 < 10 ? `0${rank + 1}` : rank + 1}
        </PieChartRank>
        <PieChartName>{this.truncateString(name, screenSize)}</PieChartName>
        <ItemValue>{OrderService.formatToCurrency(value)}</ItemValue>
      </TooltipWrapper>
    );
  };

  handleSaleOverTimeExpand = () => {
    const {
      setSelectedRowId,
      rank,
      mixpanelSectionName,
      mixpanelSecondSectionEvent,
    } = this.props;
    setSelectedRowId(rank);
    Mixpanel.track(`${mixpanelSectionName} View: ${mixpanelSecondSectionEvent}`, {});
    this.setState(state => ({
      statsExpand: false,
      saleOverTimeExpand: !state.saleOverTimeExpand,
      breakDownExpand: false,
    }));
  };

  handleBreakDownExpand = () => {
    const {
      setSelectedRowId,
      rank,
      mixpanelSectionName,
      mixpanelThirdSectionEvent,
    } = this.props;
    setSelectedRowId(rank);
    Mixpanel.track(`${mixpanelSectionName} View: ${mixpanelThirdSectionEvent}`, {});
    this.setState(state => ({
      statsExpand: false,
      saleOverTimeExpand: false,
      breakDownExpand: !state.breakDownExpand,
    }));
  };

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  handleStatsExpand = () => {
    const {
      setSelectedRowId,
      rank,
      mixpanelSectionName,
      mixpanelFirstSectionEvent,
    } = this.props;
    setSelectedRowId(rank);
    Mixpanel.track(`${mixpanelSectionName} View: ${mixpanelFirstSectionEvent}`, {});
    this.setState(state => ({
      statsExpand: !state.statsExpand,
      saleOverTimeExpand: false,
      breakDownExpand: false,
    }));
  };

  render() {
    const {
      statsExpand,
      saleOverTimeExpand,
      breakDownExpand,
      tabValue,
    } = this.state;

    const {
      rank,
      itemName,
      itemValue,
      maxValue,
      barColor,
      barChartData,
      pieChartData,
      rangeFilter,
      currentDate,
      screenSize,
      statsData,
    } = this.props;

    const formattedValue = formattedOrderTotal(itemValue / 100);

    return (
      isMobile(screenSize)
        ? (
          <MobileVersion
            rank={rank}
            itemName={itemName}
            itemValue={itemValue}
            formattedValue={formattedValue}
            maxValue={maxValue}
            barColor={barColor}
            barChartData={barChartData}
            pieChartData={pieChartData}
            generateEntry={this.generateEntry}
            statsExpand={statsExpand}
            saleOverTimeExpand={saleOverTimeExpand}
            breakDownExpand={breakDownExpand}
            filter={rangeFilter}
            currentDate={currentDate}
            screenSize={screenSize}
            tabValue={tabValue}
            handleTabChange={this.handleTabChange}
            handleStatsExpand={this.handleStatsExpand}
            handleSaleOverTimeExpand={this.handleSaleOverTimeExpand}
            handleBreakDownExpand={this.handleBreakDownExpand}
            statsData={statsData}
          />
        )
        : (
          <DesktopVersion
            rank={rank}
            itemName={itemName}
            itemValue={itemValue}
            formattedValue={formattedValue}
            maxValue={maxValue}
            barColor={barColor}
            barChartData={barChartData}
            pieChartData={pieChartData}
            generateEntry={this.generateEntry}
            statsExpand={statsExpand}
            saleOverTimeExpand={saleOverTimeExpand}
            breakDownExpand={breakDownExpand}
            filter={rangeFilter}
            currentDate={currentDate}
            screenSize={screenSize}
            handleStatsExpand={this.handleStatsExpand}
            handleSaleOverTimeExpand={this.handleSaleOverTimeExpand}
            handleBreakDownExpand={this.handleBreakDownExpand}
            statsData={statsData}
          />
        )
    );
  }
}

CollapsiblePanel.defaultProps = {
  barColor: styles.colors.orange,
  itemName: 'Item Name',
  itemValue: 0.00,
  maxValue: 100,
};

export default CollapsiblePanel;
