import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icons from './assets/icons.svg';

const IconStyled = styled.svg`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: inline-block;
  fill: ${props => props.color};
  cursor: pointer;
  vertical-align: middle;
`;

const Icon = (props) => {
  const { name } = props;
  return (
    <IconStyled {...props}>
      <use xlinkHref={`${Icons}#${name}`} />
    </IconStyled>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Icon.defaultProps = {
  color: '#ffffff',
  height: 20,
  width: 20,
};

export default Icon;
