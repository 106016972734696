import {
  isDay,
  isWeek,
  isMonth,
  isYear,
} from '../../../helpers/dateRange';
import {
  MAX_DAY,
  MAX_WEEK,
  MAX_MONTH,
  MAX_YEAR,
} from '../../../constants';

let data = [];

const getMonthData = (orders) => {
  const monthTotals = {};
  const monthData = Array(MAX_MONTH + 1).fill(0);
  orders.forEach((order) => {
    const date = new Date(order.timestamp).getDate();
    if (Object.prototype.hasOwnProperty.call(monthTotals, date)) {
      monthTotals[date] += order.total;
      monthData[date - 1] = monthTotals[date] * 0.01;
    } else {
      monthTotals[date] = order.total;
      monthData[date - 1] = monthTotals[date] * 0.01;
    }
  });
  return monthData;
};

const getDayData = (orders) => {
  const dayData = Array(MAX_DAY + 1).fill(0);
  orders.forEach((order) => {
    const hours = new Date(order.timestamp).getHours();
    dayData[hours] += order.total * 0.01;
  });
  return dayData;
};

const getWeekData = (orders, labels) => {
  const weekTotals = {};
  const weekData = Array(MAX_WEEK + 1).fill(0);
  const intLabels = labels.map(item => parseInt(item, 10));
  orders.forEach((order) => {
    const date = new Date(order.timestamp).getDate();
    const index = intLabels.indexOf(date);
    if (Object.prototype.hasOwnProperty.call(weekTotals, date)) {
      weekTotals[date] += order.total;
      weekData[index] = weekTotals[date] * 0.01;
    } else {
      weekTotals[date] = order.total;
      weekData[index] = weekTotals[date] * 0.01;
    }
  });
  return weekData;
};

const getYearData = (orders) => {
  const yearTotals = {};
  const yearData = Array(MAX_YEAR).fill(0);
  orders.forEach((order) => {
    const month = new Date(order.timestamp).getMonth();
    if (Object.prototype.hasOwnProperty.call(yearTotals, month)) {
      yearTotals[month] += order.total;
      yearData[month] = yearTotals[month] * 0.01;
    } else {
      yearTotals[month] = order.total;
      yearData[month] = yearTotals[month] * 0.01;
    }
  });
  return yearData;
};

const configureData = (orders, filter, labels) => {
  if (isDay(filter)) {
    data = getDayData(orders);
  } if (isWeek(filter)) {
    data = getWeekData(orders, labels);
  } if (isMonth(filter)) {
    data = getMonthData(orders);
  } if (isYear(filter)) {
    data = getYearData(orders, labels);
  }
  return data;
};

export const setDateData = (mData) => { data = mData; };
export const getData = () => data;
export const resetData = () => { data = []; };

export default configureData;
