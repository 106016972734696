import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';

import thunk from 'redux-thunk';
import {
  coreMiddleware, orderMiddleware, salesTrendsMiddleware, statsMiddleware, metricsMiddleware,
} from '../middleware';
import reducers from '.';

const middleware = applyMiddleware(
  thunk,
  coreMiddleware,
  orderMiddleware,
  statsMiddleware,
  salesTrendsMiddleware,
  metricsMiddleware,
);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = composeEnhancers(middleware);

export default function configureStore(initialState) {
  return createStore(
    reducers,
    initialState,
    enhancers,
  );
}
