import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tab, Tabs, Collapse } from '@material-ui/core';
import styles from '../../../styles';
import { BarChart } from '..';
import LinearProgress from '../LinearProgressBar';
import {
  Container,
  Row,
  Rank,
  Stats,
  ExpandedStats,
  ExpandedContent,
  ItemName,
  ItemValue,
  LinearBar,
  TabWrapper,
  TooltipContainer,
} from './CollapsiblePanel';
import Statistics from './Statistics';

const MobileVersion = ({
  itemName,
  itemValue,
  formattedValue,
  maxValue,
  rank,
  screenSize,
  barColor,
  barChartData,
  pieChartData,
  generateEntry,
  statsExpand,
  handleStatsExpand,
  handleTabChange,
  tabValue,
  filter,
  currentDate,
  statsData,
}) => {
  const TabStyled = withStyles({
    root: {
      textTransform: 'none',
      minHeight: '30px',
      fontFamily: styles.typography.fonts.openSans,
      fontWeight: styles.typography.weights.medium,
      fontSize: styles.typography.sizes.smaller,
      color: styles.colors.white,
      opacity: 1,
    },
    selected: {
      backgroundColor: styles.colors.grayOpacity,
    },
  })(Tab);

  const TabsStyled = withStyles({
    root: {
      minHeight: '40px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    centered: {
      padding: '2px',
      borderBottom: `solid 1px ${styles.colors.black}`,
    },
    indicator: {
      backgroundColor: 'transparent',
    },
  })(Tabs);

  return (
    <Container>
      <Row>
        <Rank screenSize={screenSize}>{ rank < 10 ? `0${rank}` : rank}</Rank>
        <Stats screenSize={screenSize} selected={statsExpand} onClick={handleStatsExpand}>
          <ExpandedStats>
            <ItemName>{itemName}</ItemName>
            <ItemValue>{formattedValue}</ItemValue>
            <LinearBar>
              <LinearProgress barColor={barColor} value={itemValue} maxValue={maxValue} />
            </LinearBar>
          </ExpandedStats>
        </Stats>
      </Row>
      <Collapse in={statsExpand} timeout="auto" unmountOnExit>
        <ExpandedContent>
          <TabWrapper>
            <TabsStyled value={tabValue} onChange={handleTabChange} variant="fullWidth" centered>
              <TabStyled label="Stats" />
              <TabStyled label="Over Time" />
              <TabStyled label="Breakdown" />
            </TabsStyled>
          </TabWrapper>
          {tabValue === 0 && (<Statistics screenSize="mobile" statsData={statsData} />)}
          {tabValue === 1 && (
            <div>
              <BarChart
                currentDate={currentDate}
                filter={filter}
                barChartData={barChartData}
                screenSize={screenSize}
              />
            </div>
          )}
          {tabValue === 2 && (
            <TooltipContainer>
              {pieChartData.tooltipData.map(data => generateEntry(data))}
            </TooltipContainer>
          )}
        </ExpandedContent>
      </Collapse>
    </Container>
  );
};

MobileVersion.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemValue: PropTypes.number.isRequired,
  formattedValue: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  screenSize: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  barColor: PropTypes.string.isRequired,
  barChartData: PropTypes.instanceOf(Object).isRequired,
  pieChartData: PropTypes.instanceOf(Object).isRequired,
  generateEntry: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  statsExpand: PropTypes.bool.isRequired,
  handleStatsExpand: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  maxValue: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
  statsData: PropTypes.instanceOf(Object).isRequired,
};

export default MobileVersion;
