import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const MIN = 0;

// Function to normalise the values (MIN / MAX could be integrated)
// Value should be a percentage and max should be the largest percentage
const normalise = (value, max) => (value - MIN) * 100 / (max - MIN);

const styles = {
  root: {
    height: 2,
  },
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  dashedColorPrimary: {
    backgroundImage: 'none',
  },
};

const StyledLinearProgress = styled(({ barColor, ...other }) => (
  <LinearProgress
    {...other}
  />
))`
  & .bar-color {
    background-color: ${props => props.barColor}
  }
`;

const LinearProgressBar = ({ classes, barColor, value, maxValue }) => (
  <StyledLinearProgress
    classes={{
      root: classes.root,
      colorPrimary: classes.colorPrimary,
      dashedColorPrimary: classes.dashedColorPrimary,
      barColorPrimary: 'bar-color',
    }}
    variant="buffer"
    valueBuffer={0}
    barColor={barColor}
    value={normalise(value, maxValue)}
  />
);

LinearProgressBar.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  barColor: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
};

export default withStyles(styles)(LinearProgressBar);
