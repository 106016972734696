import styled from 'styled-components';
import styles from '../../styles';

const StatLabel = styled.div`
  flex: 0 0 104px;
  text-align: right;
  color: ${styles.colors.lightGray};
  font-size: ${styles.typography.sizes.small}px;
  font-family: ${styles.typography.fonts.openSans};
  font-weight: ${styles.typography.weights.regular};
  margin-right: 8px;
`;

export default StatLabel;
