const typography = {};

typography.fonts = {
  openSans: 'Open Sans',
};

typography.sizes = {
  smaller: 12,
  small: 14,
  normal: 16,
  medium: 18,
  mediumLarge: 20,
  mediumLarger: 22,
  large: 24,
  hero: 26,
  larger: 36,
  huge: 56,
};

typography.weights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export default typography;
