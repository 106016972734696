import {
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { tabValues } from '../../constants';
import Mixpanel from '../../utils/mixpanel';
import { Title } from '../shared';
import { getTitleFromRangeFilter, isLargestInRow } from './SalesTrendsHelper';

const SalesTrendsStyled = styled.section`
  grid-row: header / end;
  grid-column: start / end;
`;

const Root = styled.section`
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  overflow-x: auto;
  background-color: ${styles.colors.black};
  &.desktop {
    grid-template-columns: [start] repeat(12, 1fr) [end];
    .paper {
      grid-column: 3 / 11;
    }
  }
  &.tablet {
    grid-template-columns: [start] repeat(6, 1fr) [end];
    .paper {
      grid-column: start / end;
    }
  }
  &.mobile {
    grid-template-columns: [start] repeat(3, 1fr) [end];
    .paper {
      grid-column: start / end;
    }
  }
`;

const PaperStyled = styled(Paper)`
  margin: 0 20px;
`;

const TableHeadStyled = styled(TableHead)`
  background-color: ${styles.colors.black};
`;

const TableBodyStyled = styled(TableBody)`
  background-color: ${styles.colors.darkBlue};
  border-bottom-color: ${styles.colors.black};
`;

const TableCellStyled = styled(TableCell)`
  border-bottom: 1px solid ${styles.colors.black};
`;

const TitleStyled = styled(Title)`
  color: ${styles.colors.white};
  background-color: ${styles.colors.black};
  padding-left: 20px;
`;

const Header = styled.div`
  color: ${styles.colors.lightGray};
  font-weight: ${styles.typography.weights.medium};
  text-transform: uppercase;
`;

const FirstColumnStyled = styled.div`
  color: ${styles.colors.lightGray};
`;

const Text = styled.div`
  color: ${props => (props.isblue ? styles.colors.lightBlue : styles.colors.white)}
  font-weight: ${styles.typography.weights.light};
  font-size: ${styles.typography.sizes.small}px;
`;


class SalesTrends extends Component {
  componentDidMount() {
    const {
      setTab, fetchSalesTrendsData, currentDate, rangeFilter,
    } = this.props;

    setTab(tabValues.SALESTRENDS);

    fetchSalesTrendsData(currentDate.getTime(), rangeFilter);
    Mixpanel.track('Navigation: Sales Trends View', {});
  }

  componentDidUpdate(prevProps) {
    const {
      rangeFilter, currentDate, fetchSalesTrendsData,
    } = this.props;

    if (rangeFilter !== prevProps.rangeFilter || currentDate !== prevProps.currentDate) {
      fetchSalesTrendsData(currentDate.getTime(), rangeFilter);
    }
  }

  render() {
    const {
      screenSize, rangeFilter, data, maxValues,
    } = this.props;

    const title = `SALES TRENDS - ${getTitleFromRangeFilter(rangeFilter)}`;

    return (
      <SalesTrendsStyled>
        <Root className={`sales-trends ${screenSize}`}>
          <PaperStyled className="paper">
            <TitleStyled>
              {title}
            </TitleStyled>
            <Table className="table" padding="none">
              <TableHeadStyled>
                <TableRow>
                  <TableCell padding="dense"><Header>Timeframe</Header></TableCell>
                  <TableCell align="right"><Header>Total Sales</Header></TableCell>
                  <TableCell align="right"><Header>Sales Per Employee</Header></TableCell>
                  <TableCell align="right"><Header>Sales Per Customer</Header></TableCell>
                  <TableCell align="right"><Header>Sales Per Hour</Header></TableCell>
                  <TableCell align="right"><Header>Total Orders</Header></TableCell>
                  <TableCell padding="dense" align="right"><Header>Orders Per Hour</Header></TableCell>
                </TableRow>
              </TableHeadStyled>
              <TableBodyStyled>
                {data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell padding="dense" component="th" scope="row">
                      <FirstColumnStyled>
                        {row.info}
                      </FirstColumnStyled>
                    </TableCell>
                    <TableCellStyled align="right"><Text isblue={isLargestInRow(row, 'total_sales', maxValues)}>{row.total_sales}</Text></TableCellStyled>
                    <TableCellStyled align="right"><Text isblue={isLargestInRow(row, 'sales_per_employee', maxValues)}>{row.sales_per_employee}</Text></TableCellStyled>
                    <TableCellStyled align="right"><Text isblue={isLargestInRow(row, 'sales_per_customer', maxValues)}>{row.sales_per_customer}</Text></TableCellStyled>
                    <TableCellStyled align="right"><Text isblue={isLargestInRow(row, 'sales_per_hour', maxValues)}>{row.sales_per_hour}</Text></TableCellStyled>
                    <TableCellStyled align="right"><Text isblue={isLargestInRow(row, 'total_orders', maxValues)}>{row.total_orders}</Text></TableCellStyled>
                    <TableCellStyled padding="dense" align="right"><Text styles="padding-left: 50px" isblue={isLargestInRow(row, 'orders_per_hour', maxValues)}>{row.orders_per_hour}</Text></TableCellStyled>
                  </TableRow>
                ))}
              </TableBodyStyled>
            </Table>
          </PaperStyled>
        </Root>
      </SalesTrendsStyled>
    );
  }
}

SalesTrends.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  rangeFilter: PropTypes.string.isRequired,
  fetchSalesTrendsData: PropTypes.func.isRequired,
  screenSize: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  maxValues: PropTypes.instanceOf(Object),
};

SalesTrends.defaultProps = {
  maxValues: {},
};

export default SalesTrends;
