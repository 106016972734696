import { connect } from 'react-redux';
import { setTab } from '../../../modules/core';

import PieChart from './PieChart';

const dispatchProps = {
  select: setTab,
};

export default connect(null, dispatchProps)(PieChart);
