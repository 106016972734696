import mixpanel from 'mixpanel-browser';
import { REACT_APP_MIXPANEL_KEY, REACT_APP_MIXPANEL_ENABLED } from '../constants';

mixpanel.init(REACT_APP_MIXPANEL_KEY);

const envCheck = REACT_APP_MIXPANEL_ENABLED;

const Mixpanel = {
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id) => {
    if (envCheck) mixpanel.alias(id);
  },
  register: (fields) => {
    if (envCheck) mixpanel.register(fields);
  },
  track: (name, props) => {
    if (envCheck) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (envCheck) mixpanel.people.set(props);
    },
  },
};

export default Mixpanel;
