import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../styles';

const SpinnerStyled = styled.svg`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

const Spinner = (props) => {
  const {
    gradientColor1,
    gradientColor2,
  } = props;
  return (
    <SpinnerStyled {...props} viewBox="0 0 146 146">
      <defs>
        <linearGradient x1="50%" y1="-2.35%" x2="48.13%" y2="100%" id="a">
          <stop stopColor={gradientColor1} offset="0%" />
          <stop stopColor={gradientColor2} offset="100%" />
        </linearGradient>
      </defs>
      <g transform="rotate(180 84.5 84.5)" fill="none" fillRule="evenodd">
        <circle stroke="#404147" strokeWidth="5" transform="rotate(60 96 96)" cx="96" cy="96" r="70" />
        <path d="M96 166a70 70 0 1 0 0-140 70 70 0 0 0 0 140z" stroke="url(#a)" strokeWidth="6" strokeLinecap="round" strokeDasharray="218,300" transform="rotate(-24 96 96)">
          <animateTransform attributeName="transform" type="rotate" from="0 96 96" to="360 96 96" dur="0.8s" repeatCount="indefinite" />
        </path>
      </g>
    </SpinnerStyled>
  );
};

Spinner.propTypes = {
  gradientColor1: PropTypes.string,
  gradientColor2: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Spinner.defaultProps = {
  height: 90,
  width: 90,
  gradientColor1: styles.colors.gradient1,
  gradientColor2: styles.colors.gradient2,
};

export default Spinner;
