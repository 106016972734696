import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import styles from './styles';

import Logger from './utils/logger';
import HeaderBarContainer from './features/header-bar/HeaderBarContainer';
import { Loader } from './features/shared';
import { UpgradeModal, InitialMigrationModal } from './features/dashboard/modal';
import Main from './Main';

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: styles.colors.grayOpacity,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${styles.colors.black}`,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: ${styles.theme.background};
    font-family: ${styles.typography.fonts.openSans};
  };
  button {
    border-color: none;
    border-style: none;
    border-width: 0;
    padding: 0;
  };
`;

const AppStyled = styled.section`
  display: grid;
  height: 100vh;
  grid-template-rows: [start] 56px [header] auto [end];
  &.desktop {
    grid-template-columns: [start] repeat(12, 1fr) [end];
  }
  &.tablet {
    grid-template-columns: [start] repeat(6, 1fr) [end];
  }
  &.mobile {
    grid-template-columns: [start] repeat(3, 1fr) [end];
  }
`;

class App extends Component {
  static propTypes = {
    fetchBillingStatus: PropTypes.func.isRequired,
    fetchMigrationStatus: PropTypes.func.isRequired,
    upgradeModalVisibility: PropTypes.bool.isRequired,
    migrationModalVisibility: PropTypes.bool.isRequired,
    screenSize: PropTypes.string.isRequired,
    setUpgradeModalState: PropTypes.func.isRequired,
    setMigrationModalState: PropTypes.func.isRequired,
    setScreenSize: PropTypes.func.isRequired,
    setScreenOrientation: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      setScreenSize,
      setScreenOrientation,
      fetchBillingStatus,
      fetchMigrationStatus,
    } = this.props;
    window.addEventListener('resize', setScreenSize);
    window.addEventListener('resize', setScreenOrientation);
    fetchBillingStatus();
    fetchMigrationStatus();

    // Logentries initialization
    Logger.init();
  }

  componentWillUnmount() {
    const { setScreenSize, setScreenOrientation } = this.props;
    window.removeEventListener('resize', setScreenSize);
    window.removeEventListener('resize', setScreenOrientation);
  }

  render() {
    const {
      screenSize,
      upgradeModalVisibility,
      setUpgradeModalState,
      migrationModalVisibility,
      setMigrationModalState,
    } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <AppStyled className={`app ${screenSize}`}>
          <GlobalStyle />
          <HeaderBarContainer />
          <Loader />
          <Main />
          <UpgradeModal
            modalVisibility={upgradeModalVisibility}
            screenSize={screenSize}
            desktopWidth={712}
            onClose={() => setUpgradeModalState(false)}
            buttonText="Upgrade Dashboard"
            modalTitle="Upgrade to the New Dashboard"
          />
          <InitialMigrationModal
            modalVisibility={migrationModalVisibility}
            screenSize={screenSize}
            desktopWidth={712}
            onClose={() => setMigrationModalState(false)}
            buttonText="Close"
            modalTitle="Migrating Your Orders..."
          />
        </AppStyled>
      </MuiThemeProvider>
    );
  }
}

export default App;
