import { rangeValues } from '../../constants';
import { formattedOrderTotal } from '../../helpers/dateRange';

const getTitleFromRangeFilter = (rangeFilter) => {
  let titleText;
  switch (rangeFilter) {
    case rangeValues.DAY: {
      titleText = 'Daily';
      break;
    }
    case rangeValues.WEEK: {
      titleText = 'Weekly';
      break;
    }
    case rangeValues.MONTH: {
      titleText = 'Monthly';
      break;
    }
    case rangeValues.YEAR: {
      titleText = 'Yearly';
      break;
    }
    default:
      console.error('error in SalesTrendsHelper');
      break;
  }

  return titleText;
};

const isLargestInRow = (row, type, maxValues) => {
  switch (type) {
    case 'total_sales':
    case 'sales_per_employee':
    case 'sales_per_customer':
    case 'sales_per_hour':
      return row[type] === formattedOrderTotal(maxValues[type]);
    case 'total_orders':
      return row[type] === maxValues[type];
    case 'orders_per_hour':
      return row[type] === maxValues[type].toString();
    default:
      return null;
  }
};

// This is a 'contract' for the data variable (search 'design by contract' online)
const createTrendRow = (
  id,
  info,
  total_sales = 0,
  sales_per_employee = 0,
  sales_per_customer = 0,
  sales_per_hour = 0,
  total_orders = 0,
  orders_per_hour = 0,
) => (
  {
    id,
    info,
    total_sales,
    sales_per_employee,
    sales_per_customer,
    sales_per_hour,
    total_orders,
    orders_per_hour,
  }
);

export {
  getTitleFromRangeFilter,
  isLargestInRow,
  createTrendRow,
};
