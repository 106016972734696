import styled from 'styled-components';
import styles from '../../styles';

const Title = styled.div`
  font-size: ${styles.typography.sizes.hero}px;
  font-family: ${styles.typography.fonts.openSans};
  font-weight: ${styles.typography.weights.light};
  padding: 15px 0px 5px 0px;
`;

const Subtitle = styled.div`
  font-size: ${styles.typography.sizes.mediumLarge}px;
  font-family: ${styles.typography.fonts.openSans};
  font-weight: ${styles.typography.weights.light};
  padding: 0px 0px 15px 0px;`;

export {
  Title,
  Subtitle,
};
