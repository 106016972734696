import { connect } from 'react-redux';
import {
  getTab, setTab, logout, getBillingStatus, setUpgradeModalVisibility,
} from '../../modules/core';
import NavigationDropdown from './NavigationDropdown';

const mapStateToProps = state => ({
  tab: getTab(state),
  billingStatus: getBillingStatus(state),
});

const dispatchProps = {
  logout,
  select: setTab,
  setModalState: status => setUpgradeModalVisibility(status),
};

export default connect(mapStateToProps, dispatchProps)(NavigationDropdown);
