import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles';

import { isDesktop } from '../../utils/environment';
import { tabValues } from '../../constants';
import { getFilter } from '../../helpers/dateRange';

import {
  AdsPanel, LineChart, Title, Subtitle,
} from '../shared';
import Statistics from './statistics';
import PieCharts from './pie-charts';
import Mixpanel from '../../utils/mixpanel';

const DashboardStyled = styled.section`
  grid-row: header / end;
  grid-column: start / end;
  color: ${styles.colors.white};
  background-color: ${styles.colors.black};
`;

const TitleStyled = styled(Title)`
  padding-left: ${({ screenSize }) => (isDesktop(screenSize) ? 50 : 20)}px;
`;

const MerchantNameStyled = styled(Subtitle)`
  padding-left: ${({ screenSize }) => (isDesktop(screenSize) ? 50 : 20)}px
`;

class Dashboard extends React.Component {
  componentDidMount() {
    const {
      fetchOrdersWithFilter,
      fetchEmployeeStatsWithFilter,
      fetchItemStatsWithFilter,
      setTab,
    } = this.props;

    setTab(tabValues.DASHBOARD);

    const filter = getFilter(this.props);
    fetchOrdersWithFilter(filter);
    fetchEmployeeStatsWithFilter(filter);
    fetchItemStatsWithFilter(filter);
    Mixpanel.track('Navigation: Main View', {});
  }

  componentDidUpdate(prevProps) {
    const {
      currentDate,
      rangeFilter,
      fetchOrdersWithFilter,
      fetchEmployeeStatsWithFilter,
      fetchItemStatsWithFilter,
    } = this.props;

    if (currentDate !== prevProps.currentDate || rangeFilter !== prevProps.rangeFilter) {
      const filter = getFilter(this.props);
      fetchOrdersWithFilter(filter);
      fetchEmployeeStatsWithFilter(filter);
      fetchItemStatsWithFilter(filter);
    }
  }

  render() {
    const {
      screenSize,
      rangeFilter,
      orders,
      currentDate,
      billingStatus,
      merchantName,
    } = this.props;
    return (
      <DashboardStyled className={`dashboard ${screenSize}`}>
        <TitleStyled screenSize={screenSize}>DASHBOARD</TitleStyled>
        <MerchantNameStyled screenSize={screenSize}>
          {merchantName}
        </MerchantNameStyled>
        <LineChart
          screenSize={screenSize}
          filter={rangeFilter}
          orders={orders}
          currentDate={currentDate}
        />
        <Statistics screenSize={screenSize} />
        <PieCharts screenSize={screenSize} />
        <AdsPanel screenSize={screenSize} billingStatus={billingStatus} />
      </DashboardStyled>
    );
  }
}

Dashboard.propTypes = {
  billingStatus: PropTypes.string.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  orders: PropTypes.instanceOf(Array),
  merchantName: PropTypes.string,
  rangeFilter: PropTypes.string.isRequired,
  screenSize: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  fetchEmployeeStatsWithFilter: PropTypes.func.isRequired,
  fetchItemStatsWithFilter: PropTypes.func.isRequired,
  fetchOrdersWithFilter: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  orders: [],
  merchantName: '',
};

export default Dashboard;
