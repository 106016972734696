import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';

import { pieChartColors } from '../../../constants';
import {
  handleOverflow, labelsMargin, maxLabelLengthFromScreenSize,
} from './utils';
import { isDesktop } from '../../../utils/environment';

const LabelsStyled = styled.div`
  width: ${props => (isDesktop(props.screenSize) ? 120 : 90)}px;
  margin: ${props => labelsMargin(props.alignment)};
  align-items: center;
`;

const Label = styled.div`
  display: flex;
  padding: 0px 0px 16px;
`;

const LegendColor = styled.div`
  width: 4px;
  height: 32px;
  background-color: ${props => props.blockColor}
  border-radius: 6px;
`;

const LabelText = styled.div`
  width: ${({ screenSize }) => (isDesktop(screenSize) ? 150 : 90)}px;
  height: 32px;
  font-size: ${styles.typography.sizes.small}px;
  font-weight: ${styles.typography.weights.light};
  padding: 0px 8px;
  margin-top: -2px;
  text-align: right;
`;

const LabelTextLeft = styled(LabelText)`
  text-align: left;
`;

const PercentageText = styled.div`
  height: 16px;
  font-size: ${styles.typography.sizes.smaller}px;
  color: ${styles.colors.lightGray};
  text-align: right;
`;

const PercentageTextLeft = styled(PercentageText)`
  text-align: left;
`;

const leftLabel = (data, screenSize) => {
  const [
    rank, name, value, percentage,
  ] = data;
  // eslint-disable-next-line
  const labelLength = maxLabelLengthFromScreenSize(screenSize);
  const formattedPercentage = `${percentage || '<0.1'}%`;
  return (
    <Label key={name + value}>
      <LegendColor blockColor={pieChartColors[rank]} />
      <LabelTextLeft screenSize={screenSize}>
        {handleOverflow(name, labelLength)}
        <br />
        <PercentageTextLeft>{formattedPercentage}</PercentageTextLeft>
      </LabelTextLeft>
    </Label>
  );
};

const rightLabel = (data, screenSize) => {
  const [
    rank, name, value, percentage,
  ] = data;
  const labelLength = maxLabelLengthFromScreenSize(screenSize);
  const formattedPercentage = `${percentage || '<0.1'}%`;
  return (
    <Label key={name + value}>
      <LabelText screenSize={screenSize}>
        {handleOverflow(name, labelLength)}
        <br />
        <PercentageText>{formattedPercentage}</PercentageText>
      </LabelText>
      <LegendColor blockColor={pieChartColors[rank]} />
    </Label>
  );
};

const populateLabels = (props) => {
  const {
    showLabels, data, alignment, screenSize,
  } = props;
  return (
    (showLabels && data) && data.map(dataArr => (
      alignment === 'left' ? leftLabel(dataArr, screenSize) : rightLabel(dataArr, screenSize)
    )));
};

const Labels = (props) => {
  const {
    screenSize,
    alignment,
  } = props;

  return (
    <LabelsStyled screenSize={screenSize} alignment={alignment}>
      {populateLabels(props)}
    </LabelsStyled>
  );
};

Labels.propTypes = {
  screenSize: PropTypes.string.isRequired,
  alignment: PropTypes.string.isRequired,
};

Labels.defaultProps = {
  data: [],
};

export default Labels;
