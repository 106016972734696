import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getScreenSize,
  setScreenSize,
  setScreenOrientation,
  setUpgradeModalVisibility,
  getUpgradeModalVisibility,
  getMigrationModalVisibility,
  setMigrationModalVisibility,
  setBillingStatus,
  fetchBillingStatus,
  fetchMigrationStatus,
} from './modules/core';
import { getRangeFilter, getCurrentDate } from './modules/filter';

import App from './App';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  currentDate: getCurrentDate(state),
  rangeFilter: getRangeFilter(state),
  upgradeModalVisibility: getUpgradeModalVisibility(state),
  migrationModalVisibility: getMigrationModalVisibility(state),
});

export const mapDispatchToProps = dispatch => ({
  setScreenSize: () => dispatch(setScreenSize()),
  setScreenOrientation: () => dispatch(setScreenOrientation()),
  setBillingStatus: state => dispatch(setBillingStatus(state)),
  setUpgradeModalState: status => dispatch(setUpgradeModalVisibility(status)),
  setMigrationModalState: state => dispatch(setMigrationModalVisibility(state)),
  fetchBillingStatus: () => dispatch(fetchBillingStatus()),
  fetchMigrationStatus: () => dispatch(fetchMigrationStatus()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(App));
