import {
  getFirstDayOfWeek, getNumberOfDays, isDay, isMonth, isWeek, isYear, months, setNextDay,
} from './dateRange';

import { MAX_DAY, MAX_WEEK, MAX_YEAR } from '../constants';

// For line chart and bar chart
const renderLabels = (screenSize, filter, currentDate) => {
  const labels = [];
  if (isDay(filter)) {
    labels.push('12am');
    for (let i = 1; i < MAX_DAY; i += 1) {
      if (i < 12) {
        labels.push(`${i}am`);
      } else {
        labels.push(`${i === 12 ? '12' : i - 12}pm`);
      }
    }
  }
  if (isMonth(filter)) {
    for (let i = 1; i <= getNumberOfDays(currentDate); i += 1) {
      if (i < 10) {
        labels.push(`0${i}`);
      } else {
        labels.push(`${i}`);
      }
    }
  }
  if (isWeek(filter)) {
    const startRange = getFirstDayOfWeek(currentDate);
    let dateNumber = currentDate.getDate().toString();
    for (let i = 0; i < MAX_WEEK; i += 1) {
      labels.push(dateNumber < 10 ? `0${dateNumber}` : dateNumber);
      dateNumber = new Date(setNextDay(startRange)).getDate().toString();
    }
  }
  if (isYear(filter)) {
    for (let i = 0; i < MAX_YEAR; i += 1) {
      labels.push(months[i]);
    }
  }
  return labels;
};

const createChartLabels = (tooltipData) => {
  const amount = tooltipData.length;
  if (amount === 0) {
    return {
      leftSideLabels: [],
      rightSideLabels: [],
    };
  }

  let leftSideLabels = [];
  const rightSideLabels = [];
  if (amount <= 4) {
    leftSideLabels = tooltipData;
  } else if (amount === 5) {
    leftSideLabels.push(
      tooltipData[0],
      tooltipData[1],
      tooltipData[2],
    );
    rightSideLabels.push(
      tooltipData[3],
      tooltipData[4],
    );
  } else if (amount === 6) {
    leftSideLabels.push(
      tooltipData[0],
      tooltipData[1],
      tooltipData[2],
    );
    rightSideLabels.push(
      tooltipData[3],
      tooltipData[4],
      tooltipData[5],
    );
  } else if (amount >= 7) {
    leftSideLabels.push(
      tooltipData[0],
      tooltipData[1],
      tooltipData[2],
      tooltipData[3],
    );
    rightSideLabels.push(
      tooltipData[4],
      tooltipData[5],
      tooltipData[6],
    );
  }

  return {
    leftSideLabels,
    rightSideLabels,
  };
};
const removeRepeatingDecimal = number => parseFloat(number.toFixed(1));

const getOverallSales = (extraItems) => {
  const overallSales = (otherTotal, currentItem) => otherTotal + currentItem.overallSales;
  return removeRepeatingDecimal(extraItems.reduce(overallSales, 0));
};

const getTotalSales = (extraItems) => {
  const totalSales = (orderTotals, currentItem) => orderTotals + currentItem.totalSales;
  return removeRepeatingDecimal(extraItems.reduce(totalSales, 0));
};

const MAX_ENTRIES_FOR_CHART = 7;
const sumExtraEntries = (listData) => {
  const extraItems = listData.slice(MAX_ENTRIES_FOR_CHART - 1);
  return {
    name: 'All Others',
    overallSales: getOverallSales(extraItems),
    totalSales: getTotalSales(extraItems),
  };
};

const handleTooManyEntries = (listData) => {
  if (listData.length > MAX_ENTRIES_FOR_CHART) {
    const othersItem = sumExtraEntries(listData);
    const finalItems = listData.slice(0, MAX_ENTRIES_FOR_CHART - 1);
    finalItems.push(othersItem);
    return finalItems;
  }

  return listData;
};

const getPieChartData = (allStats) => {
  const rawData = [];
  const tooltipData = [];

  const stats = handleTooManyEntries(allStats);

  for (let i = 0; i < stats.length && i < MAX_ENTRIES_FOR_CHART; i += 1) {
    const data = stats[i];
    rawData.push(data.totalSales);
    tooltipData.push([
      i,
      data.name,
      data.totalSales,
      data.overallSales,
    ]);
  }

  return {
    rawData,
    tooltipData,
    chartLabels: createChartLabels(tooltipData),
  };
};

const removeUnconfirmedOrders = (orders) => {
  const cleanOrders = [];

  orders.forEach((order) => {
    if (order.total > 0) {
      cleanOrders.push(order);
    }
  });

  return cleanOrders;
};

export {
  renderLabels,
  getPieChartData,
  handleTooManyEntries,
  removeUnconfirmedOrders,
};
