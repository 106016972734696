import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles';
import { isMobile } from '../../utils/environment';

const Container = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${styles.typography.sizes.small}px;
  color: ${styles.colors.lightGray};
`;

const Rank = styled.span`
  flex: 0 1 12%;
  height: 100%;
  text-align: center;  
  padding: 0 20px;
`;

const Sales = styled.span`
  flex: 0 1 45%;
  height: 100%;
  text-align: center;
`;

const SalesOverTime = styled.span`
  flex: 0 1 25%;
  height: 100%;
  text-align: center;
`;

const Breakdown = styled.span`
min-width: 160px;
  flex: 0 1 18%;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const HeaderPanel = ({ screenSize, breakDown }) => (
  !isMobile(screenSize)
    && (
      <Container>
        <Rank>RANK</Rank>
        <Sales>SALES</Sales>
        <SalesOverTime>SALES/TIME</SalesOverTime>
        <Breakdown>{breakDown}</Breakdown>
      </Container>
    )
);

HeaderPanel.propTypes = {
  screenSize: PropTypes.string.isRequired,
  breakDown: PropTypes.string.isRequired,
};

export default HeaderPanel;
