import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getScreenSize, getTab, setTab } from '../../modules/core';
import { getCurrentDate, getRangeFilter } from '../../modules/filter';
import SalesTrends from './SalesTrends';
import { fetchSalesTrendsData, getSalesTrendsData, getMaxValues } from '../../modules/salesTrends';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  currentDate: getCurrentDate(state),
  rangeFilter: getRangeFilter(state),
  tab: getTab(state),
  data: getSalesTrendsData(state),
  maxValues: getMaxValues(state),
});

const mapDispatchToProps = {
  fetchSalesTrendsData,
  setTab,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesTrends));
