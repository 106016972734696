/* eslint-disable no-undef */
import { PROD, STAGING } from './environment';

const isProduction = process.env.NODE_ENV === PROD;
const isStaging = process.env.NODE_ENV === STAGING;

const init = () => {
  const token = process.env.REACT_APP_LOGENTRIES_TOKEN;
  // Make sure logentries run on staging and production only
  if (token && (isProduction || isStaging)) {
    LE.init(token);
  }
};

const log = (data) => {
  LE.log(data);
};


const info = (data) => {
  LE.info(data);
};

const warn = (data) => {
  LE.warn(data);
};

const error = (data) => {
  LE.error(data);
};

export default {
  init,
  log,
  info,
  warn,
  error,
};
