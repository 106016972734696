import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMigrationModalVisibility, getScreenSize } from '../../../modules/core';
import InitialMigrationModal from './InitialMigrationModal';

export const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  isVisible: getMigrationModalVisibility(state),
});

export default withRouter(connect(mapStateToProps)(InitialMigrationModal));
