import { fetch } from '../utils/api';
import { FETCH_SALES_TRENDS, setSalesTrendsData, setSalesTrendsErr } from '../modules/salesTrends';

export const fetchSalesTrendsData = (currentDate, rangeFilter) => fetch('/salesTrends', { currentDate, rangeFilter, pick: ['timestamp', 'total'] });

export const fetchSalesTrendsDataWithDispatch = async (dispatch, currentDate, rangeFilter) => {
  try {
    const data = await fetchSalesTrendsData(currentDate, rangeFilter);
    const { finalRows, maxValues } = data;

    dispatch(setSalesTrendsData(finalRows, maxValues));
  } catch (err) {
    dispatch(setSalesTrendsErr(err));
  }
};

const SalesTrendsMiddleware = ({ dispatch }) => next => (action) => {
  switch (action.type) {
    case FETCH_SALES_TRENDS: {
      fetchSalesTrendsDataWithDispatch(dispatch, action.currentDate, action.rangeFilter);
      break;
    }
    default:
  }
  return next(action);
};

export default SalesTrendsMiddleware;
