import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateRangeSelector from './DateRangeSelector';
import {
  getBillingStatus, getScreenSize, setUpgradeModalVisibility,
} from '../../modules/core';
import { getRangeFilter, getCurrentDate, setCurrentDate } from '../../modules/filter';

class DateRangeSelectorContainer extends React.Component {

  render() {
    const {
      screenSize,
      currentDate,
      rangeFilter,
      setDate,
      setModalState,
      billingStatus,
    } = this.props;
    return (
      <DateRangeSelector
        screenSize={screenSize}
        currentDate={currentDate}
        filter={rangeFilter}
        setDate={setDate}
        setModalState={setModalState}
        billingStatus={billingStatus}
      />
    );
  }
}

DateRangeSelectorContainer.propTypes = {
  billingStatus: PropTypes.string.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  rangeFilter: PropTypes.string.isRequired,
  screenSize: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  billingStatus: getBillingStatus(state),
  currentDate: getCurrentDate(state),
  rangeFilter: getRangeFilter(state),
  screenSize: getScreenSize(state),
});

const mapDispatchToProps = {
  setDate: date => setCurrentDate(date),
  setModalState: status => setUpgradeModalVisibility(status),
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeSelectorContainer);
