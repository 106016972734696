import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import typography from '../../styles/typography';
import styles from '../../styles';

const ButtonStyled = styled(Button)`
  && {
    border-radius: 22px;
    background: linear-gradient(to right, ${styles.colors.gradient1}, ${styles.colors.gradient2});
  }
`;

const ButtonTitleStyled = styled.div`
  font-family: ${typography.fonts.openSans};
  font-size: ${typography.sizes.small};
  font-weight: ${typography.weights.medium};
  text-transform: capitalize;
  padding: 2px 20px;
  height: auto;
  white-space: nowrap;
`;

const GradientButton = ({ buttonText, href }) => (
  <ButtonStyled
    variant="contained"
    color="primary"
    href={href}
  >
    <ButtonTitleStyled>{buttonText}</ButtonTitleStyled>
  </ButtonStyled>
);

GradientButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  href: PropTypes.string,
};

GradientButton.defaultProps = {
  href: '',
};

export default GradientButton;
