const colors = {
  whiteOpacity: '#ffffffde',
  white: '#ffffff',
  black: '#0f1118',
  darkBlue: '#181b23',
  gray: '#535454',
  green: '#03f286',
  lightGray: '#9b9b9b',
  lighterGray: '#23252B',
  grayOpacity: '#404147',
  gradient1: '#9d54fe',
  gradient2: '#04f9bd',

  red: '#ee683e',
  orange: '#f3a22b',
  yellow: '#f7db3b',
  cyan: '#08f5bf',
  lightBlue: '#50a7dd',
  blue: '#464fe2',
  purple: '#9854fa',
};

export default colors;
