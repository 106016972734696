/**
 * Order Module
 *
 * Responsible for the keeping orders payload in redux
 *
 */

export const initialState = {
  orders: [],
  isOrdersFetching: false,
  hasError: false,
};

// Action Types
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const ERROR_ORDERS = 'ERROR_ORDERS';

export const fetchOrders = filter => ({
  type: FETCH_ORDERS,
  filter,
});

export const setOrders = payload => ({
  type: SET_ORDERS,
  payload,
});

export const setOrdersErr = err => ({
  type: ERROR_ORDERS,
  err,
});

// Selectors
// returns raw order data, but we will introduce selectors
// or maybe a helper to format the json into how the different
// graphs expect the data
export const getRawOrders = ({ order }) => order.orders;
export const getOrderFetchingStatus = ({ order }) => order.isOrdersFetching;


// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS: {
      return { ...state, isOrdersFetching: true, hasError: false };
    }
    case SET_ORDERS: {
      return { ...state, orders: action.payload, isOrdersFetching: false };
    }
    case ERROR_ORDERS: {
      return { ...state, hasError: true, isLoading: false };
    }
    default:
      return state;
  }
}
