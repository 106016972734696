/**
 * Core Module
 *
 * Responsible for handling Core states
 *
 */
import { createSelector } from 'reselect';
import { ActionsHelper } from '../helpers';
import { getScreenOrientationFromWindow, getScreenSizeFromWindow } from '../utils/environment';
import { clearCookieValue, redirectToCloverAuth } from '../utils/authentication';

export const initialState = {
  tab: '',
  screenSize: getScreenSizeFromWindow(),
  screenOrientation: getScreenOrientationFromWindow(),
  billingStatus: '',
  error: '',
  isUpgradeModalVisible: false,
  isMigrationModalVisible: false,
  merchant: {},
};

// Action Types
export const SET_UI_STATE_TAB = 'SET_UI_STATE_TAB';
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
export const SET_SCREEN_ORIENTATION = 'SET_SCREEN_ORIENTATION';
export const SET_BILLING_STATUS = 'SET_BILLING_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const FETCH_BILLING_STATUS = 'FETCH_BILLING_STATUS';
export const FETCH_MIGRATION_STATUS = 'FETCH_MIGRATION_STATUS';
export const SET_UPGRADE_MODAL_VISIBILITY = 'SET_UPGRADE_MODAL_VISIBILITY';
export const SET_MIGRATION_MODAL_VISIBILITY = 'SET_MIGRATION_MODAL_VISIBILITY';
export const SET_MERCHANT = 'SET_MERCHANT';
export const SET_MIXPANEL_DATA_FETCHED = 'SET_MIXPANEL_DATA_FETCHED';

// Action Creators
export const setTab = ActionsHelper.createBasicStateAction(SET_UI_STATE_TAB);
export const setError = ActionsHelper.createBasicStateAction(SET_ERROR);
export const setScreenSize = ActionsHelper.createBasicStateAction(SET_SCREEN_SIZE);
export const setBillingStatus = ActionsHelper.createBasicStateAction(SET_BILLING_STATUS);
export const fetchBillingStatus = ActionsHelper.createBasicStateAction(FETCH_BILLING_STATUS);
export const setScreenOrientation = ActionsHelper.createBasicStateAction(SET_SCREEN_ORIENTATION);
export const fetchMigrationStatus = ActionsHelper.createBasicStateAction(FETCH_MIGRATION_STATUS);
export const setUpgradeModalVisibility = ActionsHelper.createBasicStateAction(
  SET_UPGRADE_MODAL_VISIBILITY,
);
export const setMigrationModalVisibility = ActionsHelper.createBasicStateAction(
  SET_MIGRATION_MODAL_VISIBILITY,
);
export const setMerchant = ActionsHelper.createBasicStateAction(SET_MERCHANT);
export const setMixpanelDataFetched = ActionsHelper.createBasicStateAction(
  SET_MIXPANEL_DATA_FETCHED,
);

export const logout = () => () => {
  clearCookieValue('jwt');
  redirectToCloverAuth();
};

// Selectors
export const getCore = state => state.core;
export const getTab = createSelector(getCore, state => state.tab);
export const getScreenSize = createSelector(getCore, state => state.screenSize);
export const getMerchantName = createSelector(getCore, state => state.merchant.merchantName);
export const getBillingStatus = createSelector(getCore, state => state.billingStatus);
export const getScreenOrientation = createSelector(getCore, state => state.screenOrientation);
export const getUpgradeModalVisibility = createSelector(
  getCore, state => state.isUpgradeModalVisible,
);
export const getMigrationModalVisibility = createSelector(
  getCore, state => state.isMigrationModalVisible,
);

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_UI_STATE_TAB:
      return { ...state, tab: action.state };
    case SET_SCREEN_SIZE: {
      const newScreenSize = getScreenSizeFromWindow();
      if (newScreenSize === state.screenSize) { return state; }
      return { ...state, screenSize: newScreenSize };
    }
    case SET_SCREEN_ORIENTATION: {
      const newScreenOrientation = getScreenOrientationFromWindow();
      if (newScreenOrientation === state.screenOrientation) { return state; }
      return { ...state, screenOrientation: newScreenOrientation };
    }
    case SET_BILLING_STATUS:
      return { ...state, billingStatus: action.state };
    case SET_UPGRADE_MODAL_VISIBILITY:
      return { ...state, isUpgradeModalVisible: action.state };
    case SET_MIGRATION_MODAL_VISIBILITY:
      return { ...state, isMigrationModalVisible: action.state };
    case SET_ERROR:
      return { ...state, error: action.state };
    case SET_MERCHANT:
      return { ...state, merchant: action.state };
    case SET_MIXPANEL_DATA_FETCHED:
      return { ...state, mixpanelDataFetched: true };
    default:
      return state;
  }
}
