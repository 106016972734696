import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from 'react-router-dom';
import Done from '@material-ui/icons/Done';
import Icon from '../shared/Icon';
import Mixpanel from '../../utils/mixpanel';

import { menuOptions, tabValues, subscriptionTiers } from '../../constants';
import styles from '../../styles';


const NavigationDropDownStyled = styled.div`
  flex: 0.1 1 auto;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  top: -8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid ${styles.colors.grayOpacity};
  position: relative;
`;

export const menuItemStyle = {
  color: styles.colors.white,
  fontWeight: styles.typography.weights.regular,
  fontFamily: styles.typography.fonts.openSans,
  textTransform: 'capitalize',
  justifyContent: 'space-between',
  width: 180,
};

export const requestAFeatureStyle = {
  color: styles.colors.lightBlue,
  fontWeight: styles.typography.weights.regular,
  fontFamily: styles.typography.fonts.openSans,
  textTransform: 'capitalize',
  justifyContent: 'space-between',
  width: 180,
};

class NavigationDropDown extends React.PureComponent {
  static propTypes = {
    select: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    logout: PropTypes.func.isRequired,
    setModalState: PropTypes.func.isRequired,
    billingStatus: PropTypes.string.isRequired,
  };

  state = {
    anchorEl: null,
    showArrow: false,
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, showArrow: true });
  };

  handleMenuItemClick = (event, { label, path }) => {
    const {
      select, history, billingStatus, setModalState,
    } = this.props;
    if (billingStatus === subscriptionTiers.FREE && label === tabValues.SALESTRENDS) {
      setModalState(true);
    } else {
      select(label);
      history.push(path);
    }
    this.handleClose();
  };

  handleClose = () => this.setState({ anchorEl: null, showArrow: false });

  mailToRequestAFeature = () => {
    Mixpanel.track('Feature Request', {});
    window.open('mailto:contact+dashboard@infuse.us?subject=Dashboard Feature Request');
  };

  handleLogout = () => {
    const { logout } = this.props;
    logout();
    Mixpanel.track('Logout', {});
  }

  render() {
    const { anchorEl, showArrow } = this.state;
    const { tab } = this.props;

    return (
      <NavigationDropDownStyled className="navigation-dropdown">
        <IconButton
          aria-label="Home Bar Menu"
          aria-owns={anchorEl ? 'home-menu' : null}
          aria-haspopup
          onClick={this.handleMenuClick}
          color="inherit"
        >
          <Icon name="hamburger" width={27} height={27} />
        </IconButton>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition>
          { showArrow ? <ArrowUp /> : <div />}
          <Menu
            id="home-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {menuOptions.map(option => (
              <MenuItem
                style={menuItemStyle}
                key={option.label}
                selected={option.label === tab}
                onClick={event => this.handleMenuItemClick(event, option)}
              >
                {option.name}
                { option.label === tab
                  && <Done />
                }
              </MenuItem>
            ))}
            <MenuItem style={menuItemStyle} onClick={this.handleLogout}>Logout</MenuItem>
            <MenuItem style={requestAFeatureStyle} onClick={this.mailToRequestAFeature}>
              Request A Feature
            </MenuItem>
          </Menu>
        </Popper>
      </NavigationDropDownStyled>
    );
  }
}

export default withRouter(NavigationDropDown);
