import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';

import ItemSalesChartContainer from './ItemSalesChartContainer';
import EmployeeSalesChartContainer from './EmployeeSalesChartContainer';

const PieChartStyled = styled.div`
  display: grid;
  flex-wrap: wrap;
  padding-top: 32px;
  &.desktop {
    grid-template-columns: [start] repeat(12, 1fr) [end];
    .itemSales {
      margin-left: -40px;
      grid-column: 3 / 6;
    }
    .employeeSales {
      grid-column: 8 / 11;
    }
  }
  &.tablet {
    grid-template-columns: [start] repeat(6, 1fr) [end];
    .itemSales {
      grid-column: start / 4;
    }
    .employeeSales {
      margin-left: -40px;
      grid-column: 4 / end;
    }
  }
  &.mobile {
    grid-template-columns: [start] repeat(3, 1fr) [end];
    .itemSales {
      grid-column: 2 / 3;
    }
    .employeeSales {
      margin-top: 48px;
      grid-column: 2 / 3;
    }
  }
`;

const PieChartHeader = styled.div`
  text-align: center;
  font-size: ${styles.typography.sizes.medium}px;
  font-weight: ${styles.typography.weights.light};
`;

const PieCharts = ({
  screenSize,
}) => (
  <PieChartStyled className={`charts ${screenSize}`}>
    <div className="itemSales">
      <PieChartHeader>Sales by Item</PieChartHeader>
      <ItemSalesChartContainer screenSize={screenSize} />
    </div>
    <div className="employeeSales">
      <PieChartHeader>Sales By Employee</PieChartHeader>
      <EmployeeSalesChartContainer screenSize={screenSize} />
    </div>
  </PieChartStyled>
);

PieCharts.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

export default PieCharts;
