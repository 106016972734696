/**
 * Metrics Module
 *
 * Responsible for tracking events with mixpanel
 *
 */

export const initialState = {
  isCurrentUserIdentified: false,
  currentUserId: null,
};

// Action Types
export const MIXPANEL_IDENTIFY = 'MIXPANEL_IDENTIFY';
export const MIXPANEL_TRACK_EVENT = 'MIXPANEL_TRACK_EVENT';

export const setMixpanelIdentify = id => ({
  type: MIXPANEL_IDENTIFY,
  id,
});

export const mixpanelTrackEvent = event => ({
  type: MIXPANEL_TRACK_EVENT,
  event,
});

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MIXPANEL_IDENTIFY: {
      return { ...state, isCurrentUserIdentified: true, currentUserId: action.id };
    }
    default:
      return state;
  }
}
