import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import styles from '../../../styles';

import { pieChartColors } from '../../../constants';
import { isDesktop } from '../../../utils/environment';
import Mixpanel from '../../../utils/mixpanel';

import Tooltip from './Tooltip';
import Labels from './Labels';
import Icon from '../Icon';

const ChartStyled = styled.div`
  height: ${props => (props.small ? 80 : 186)}px;
  width: ${props => (props.small ? 160 : 360)}px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: ${props => (props.small ? 0 : 22)}px;
`;

const PieChartStyled = styled.div`
  height: ${props => (props.small ? 80 : 186)}px;
  width: ${props => (props.small ? 160 : 360)}px;
  position: relative;
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: ${props => (props.small ? 24 : 45)}px;
  left: ${props => (props.small ? 65 : 123)}px;
`;

const SeeMoreText = styled.div`
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: ${styles.typography.sizes.smaller}px;
  font-weight: ${styles.typography.weights.medium};
  color: ${styles.colors.lightBlue}
  z-index: 3;
`;

class PieChart extends React.Component {
  state = {
    seeMore: false,
    displayTooltip: false,
    shouldAnimate: true,
    disableTooltip: false,
  };

  componentDidUpdate(prevProps) {
    const { screenSize, rawData } = this.props;
    if (screenSize !== prevProps.screenSize) {
      this.enableAnimation();
    }
    this.disableTooltipIfRawDataIsEmpty(rawData);
  }

  enableAnimation = () => {
    this.setState({ shouldAnimate: true });
  };

  disableTooltipIfRawDataIsEmpty = (rawData) => {
    const { disableTooltip } = this.state;

    if (rawData === null || rawData.length === 0) {
      if (disableTooltip === false) {
        this.setState({ disableTooltip: true });
      }
    } else if (disableTooltip === true) {
      this.setState({ disableTooltip: false });
    }
  };

  disableAnimation = () => {
    this.setState({ shouldAnimate: false });
  };

  setSeeMore = () => {
    const { seeMore } = this.state;
    this.setState({ seeMore: !seeMore });
    this.disableAnimation();
    if (!seeMore) {
      const { seeMoreData } = this.props;
      Mixpanel.track(`Main View: ${seeMoreData.label} - See More`, {});
    }
  };

  flipTooltipState = () => {
    const { displayTooltip, disableTooltip } = this.state;
    if (disableTooltip === false) {
      this.setState({ displayTooltip: !displayTooltip });
      this.disableAnimation();
    }
  };

  // We have to guard against mobile/tablet as taps are translated to
  // mouse interactions, and this causes onMouseEnter & onMouseLeave
  // to trigger back to back.
  showTooltip = () => {
    const { screenSize } = this.props;
    const { disableTooltip } = this.state;
    if (isDesktop(screenSize) && disableTooltip === false) {
      this.setState({ displayTooltip: true });
      this.disableAnimation();
    }
  };

  hideTooltip = () => {
    const { screenSize } = this.props;
    const { disableTooltip } = this.state;
    if (isDesktop(screenSize) && disableTooltip === false) {
      this.setState({ displayTooltip: false });
      this.disableAnimation();
    }
  };

  navigate = () => {
    const { select, history, seeMoreData } = this.props;
    select(seeMoreData.label);
    history.push(seeMoreData.path);
  };

  render() {
    const {
      rawData,
      chartTitle,
      tooltipData,
      chartLabels,
      iconName,
      screenSize,
      small,
    } = this.props;
    const {
      shouldAnimate, seeMore, displayTooltip,
    } = this.state;


    // We need to slice off the colors we need since we
    // are displaying the data counter-clockwise.
    const colors = pieChartColors.slice(0, rawData.length).reverse();

    const data = {
      datasets: [{
        data: rawData.slice().reverse(),
        backgroundColor: colors,
        borderColor: styles.colors.black,
        borderWidth: 3,
      }],
    };

    const options = {
      cutoutPercentage: 88,
      animation: {
        duration: shouldAnimate ? 1000 : 0,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };

    const showLabels = !small && (isDesktop(screenSize) || seeMore);

    // tooltipData is sorted by most sales -> least, and totalSales is in index 3.
    // So this grabs the highest amount of sales for the data.
    const maxValue = tooltipData[0] ? tooltipData[0][3] : 0;

    return (
      <ChartStyled onMouseLeave={this.hideTooltip} small={small}>
        {chartTitle && (
          <Tooltip
            title={chartTitle}
            data={tooltipData}
            maxValue={maxValue}
            show={displayTooltip}
            seeMoreLink={this.navigate}
            screenSize={screenSize}
          />
        )}
        <Labels screenSize={screenSize} data={chartLabels.leftSideLabels} alignment="right" showLabels={showLabels} />
        <PieChartStyled small={small}>
          <IconStyled
            name={iconName}
            small={small}
            height={small ? 30 : 60}
            width={small ? 30 : 60}
            onClick={this.flipTooltipState}
            onMouseEnter={this.showTooltip}
          />
          <Doughnut data={data} options={options} key={Math.random()} />
        </PieChartStyled>
        <Labels screenSize={screenSize} data={chartLabels.rightSideLabels} alignment="left" showLabels={showLabels} />
        {!isDesktop(screenSize) && !small && (
          <SeeMoreText onClick={this.setSeeMore}>
            {seeMore ? 'HIDE' : 'SEE MORE'}
          </SeeMoreText>
        )}
      </ChartStyled>
    );
  }
}

PieChart.propTypes = {
  chartTitle: PropTypes.string,
  chartLabels: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  iconName: PropTypes.string,
  rawData: PropTypes.instanceOf(Array),
  screenSize: PropTypes.string.isRequired,
  seeMoreData: PropTypes.instanceOf(Object),
  select: PropTypes.func.isRequired,
  small: PropTypes.bool,
  tooltipData: PropTypes.instanceOf(Array),
};

PieChart.defaultProps = {
  chartTitle: '',
  chartLabels: {},
  iconName: 'portrait',
  rawData: [],
  seeMoreData: {},
  small: false,
  tooltipData: [],
};

export default withRouter(PieChart);
