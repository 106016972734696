import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';
import ReusableModal from '../../shared/Modal';

const ModalTextStyled = styled.p`
  text-align: left;
  font-size: ${styles.typography.sizes.medium}px;
  font-weight: ${styles.typography.weights.light};
`;


class InitialMigrationModal extends React.Component {
  onHandleClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const {
      isVisible,
      screenSize,
      desktopWidth,
      modalTitle,
    } = this.props;
    return (
      <ReusableModal
        modalVisibility={isVisible}
        screenSize={screenSize}
        desktopWidth={desktopWidth}
        modalTitle={modalTitle}
        onClose={this.onHandleClose}
        disableButton
      >
        <ModalTextStyled>
        Since this is your first time using the Dashboard, we need to sync your orders.
        An email will be sent to you when the sync is completed and the Dashboard is ready for use.
        </ModalTextStyled>
      </ReusableModal>
    );
  }
}

InitialMigrationModal.propTypes = {
  desktopWidth: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  screenSize: PropTypes.string.isRequired,
};

export default InitialMigrationModal;
