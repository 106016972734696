export const redirectToAuth = (merchantId, clientId, code) => {
  const { REACT_APP_API_HOST } = process.env;
  window.location.href = `${REACT_APP_API_HOST}/oauth?merchantId=${merchantId}&clientId=${clientId}&code=${code}`;
};
export const redirectToCloverAuth = (merchantId) => {
  const { REACT_APP_API_HOST } = process.env;

  console.log('merchant', merchantId);
  window.location.href = `${REACT_APP_API_HOST}/oauth/authorize${
    merchantId ? `?merchantId=${merchantId}` : ''
  }`;
};

export const clearCookieValue = (cookieKey) => {
  document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export default {
  redirectToCloverAuth,
  clearCookieValue,
};
