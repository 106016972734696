import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import {
  getUpgradeModalVisibility,
  getScreenSize,
  setUpgradeModalVisibility,
  getBillingStatus,
  setTab, getTab,
  getMerchantName,
} from '../../modules/core';
import { getRangeFilter, getCurrentDate, setCurrentDate } from '../../modules/filter';
import { fetchOrders, getRawOrders } from '../../modules/order';
import { fetchEmployeeStats, fetchItemStats } from '../../modules/statistics';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  rangeFilter: getRangeFilter(state),
  orders: getRawOrders(state),
  currentDate: getCurrentDate(state),
  modalIsVisible: getUpgradeModalVisibility(state),
  billingStatus: getBillingStatus(state),
  tab: getTab(state),
  merchantName: getMerchantName(state),
});

const mapDispatchToProps = {
  setModalState: setUpgradeModalVisibility,
  setTab,
  setDate: date => setCurrentDate(date),
  fetchEmployeeStatsWithFilter: filter => fetchEmployeeStats(filter),
  fetchItemStatsWithFilter: filter => fetchItemStats(filter),
  fetchOrdersWithFilter: filter => fetchOrders(filter),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
