import Mixpanel from '../utils/mixpanel';
import {
  MIXPANEL_TRACK_EVENT,
  setMixpanelIdentify,
} from '../modules/metrics';

import {
  SET_MIXPANEL_DATA_FETCHED,
} from '../modules/core';

export const performMixpanelSetup = async (dispatch, fields) => {
  const { APP_VERSION } = process.env;

  const { billingStatus, merchant } = fields;

  if (!merchant.id) {
    return;
  }

  Mixpanel.register({
    appVersion: APP_VERSION || '0.1.0',
    tier: billingStatus,
    email: merchant.email,
    merchantId: merchant.id,
  });

  Mixpanel.identify(merchant.id);

  Mixpanel.people.set({
    merchantId: merchant.id,
    tier: billingStatus,
    email: merchant.email,
    name: merchant.name,
  });

  dispatch(setMixpanelIdentify(merchant.id));
};

export const performMixpanelTrack = async (dispatch, event) => {
  Mixpanel.track(event);
};

const MetricsMiddleware = ({ dispatch, getState }) => next => (action) => {
  switch (action.type) {
    case SET_MIXPANEL_DATA_FETCHED: {
      const { core } = getState();
      const { billingStatus, merchant } = core;
      performMixpanelSetup(dispatch, { billingStatus, merchant });
      break;
    }
    case MIXPANEL_TRACK_EVENT: {
      performMixpanelTrack(dispatch, action.event);
      break;
    }
    default:
  }
  return next(action);
};


export default MetricsMiddleware;
