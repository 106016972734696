/**
 * Sales Trend Module
 *
 * Responsible for managing the Sales Trends data in Redux
 *
 */

import { createSelector } from 'reselect';

// Initial state
export const initialState = {
  data: [],
  isSalesTrendsFetching: false,
  error: false,
};

// Action Types
export const FETCH_SALES_TRENDS = 'FETCH_SALES_TRENDS';
export const SET_SALES_TRENDS = 'SET_SALES_TRENDS';
export const ERROR_SALES_TRENDS = 'ERROR_SALES_TRENDS';

// Actions
export const fetchSalesTrendsData = (currentDate, rangeFilter) => ({
  type: FETCH_SALES_TRENDS,
  currentDate,
  rangeFilter,
});

export const setSalesTrendsData = (data, maxValues) => ({
  type: SET_SALES_TRENDS,
  data,
  maxValues,
});

export const setSalesTrendsErr = err => ({
  type: ERROR_SALES_TRENDS,
  err,
});

// Selectors
export const getSalesTrends = state => state.salesTrends;
export const getSalesTrendsData = createSelector(getSalesTrends, state => state.data);
export const getMaxValues = createSelector(getSalesTrends, state => state.maxValues);
export const getSalesTrendsFetchingStatus = createSelector(getSalesTrends, state => state.isSalesTrendsFetching);

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SALES_TRENDS: {
      return { ...state, isSalesTrendsFetching: true, error: false };
    }
    case SET_SALES_TRENDS: {
      return {
        ...state, data: action.data, maxValues: action.maxValues, isSalesTrendsFetching: false,
      };
    }
    case ERROR_SALES_TRENDS: {
      return { ...state, error: true, isLoading: false };
    }
    default:
      return state;
  }
}
