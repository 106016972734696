const getOverallPercent = (value, total) => {
  const percentFloat = (value * 100) / total;
  const overallPercent =
      percentFloat < 0.1 ? 0 : parseFloat(percentFloat.toFixed(1));
  if (overallPercent === 100) {
    return value === total ? 100 : Math.trunc(percentFloat * 10) / 10;
  }
  return overallPercent;
};

module.exports = {
  getOverallPercent,
};
