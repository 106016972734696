import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import LinearProgress from '../LinearProgressBar';
import { BarChart, PieChart } from '..';
import { DESKTOP } from '../../../utils/environment';
import {
  Row,
  Rank,
  Stats,
  Container,
  ExpandedStats,
  ExpandedContent,
  ItemName,
  ItemValue,
  LinearBar,
  Flex,
  SaleOverTime,
  BreakDown,
  TooltipContainer,
  PieChartContainer,
} from './CollapsiblePanel';
import Statistics from './Statistics';

const DesktopVersion = ({
  itemName,
  itemValue,
  formattedValue,
  maxValue,
  rank,
  screenSize,
  barColor,
  barChartData,
  pieChartData,
  generateEntry,
  statsExpand,
  saleOverTimeExpand,
  breakDownExpand,
  handleStatsExpand,
  handleSaleOverTimeExpand,
  handleBreakDownExpand,
  filter,
  currentDate,
  statsData,
}) => (
  <Container>
    <Row>
      <Rank screenSize={screenSize}>{rank < 10 ? `0${rank}` : rank}</Rank>
      <Stats screenSize={screenSize} selected={statsExpand} onClick={handleStatsExpand}>
        <ExpandedStats>
          <ItemName>{itemName}</ItemName>
          <ItemValue>{formattedValue}</ItemValue>
          <LinearBar>
            <LinearProgress barColor={barColor} value={itemValue} maxValue={maxValue} />
          </LinearBar>
        </ExpandedStats>
      </Stats>
      <SaleOverTime selected={saleOverTimeExpand} onClick={handleSaleOverTimeExpand}>
        <BarChart
          currentDate={currentDate}
          barChartData={barChartData}
          filter={filter}
          screenSize={screenSize}
          small
        />
      </SaleOverTime>
      <BreakDown selected={breakDownExpand} onClick={handleBreakDownExpand}>
        <PieChart {...pieChartData} screenSize={DESKTOP} small />
      </BreakDown>
    </Row>
    <Collapse in={statsExpand} timeout="auto" unmountOnExit>
      <ExpandedContent>
        <Statistics
          statsData={statsData}
          screenSize="desktop"
        />
      </ExpandedContent>
    </Collapse>
    <Collapse in={saleOverTimeExpand} timeout="auto" unmountOnExit>
      <ExpandedContent>
        <BarChart
          currentDate={currentDate}
          barChartData={barChartData}
          filter={filter}
          screenSize={screenSize}
        />
      </ExpandedContent>
    </Collapse>
    <Collapse in={breakDownExpand} timeout="auto" unmountOnExit>
      <ExpandedContent>
        <Flex>
          <PieChartContainer>
            <PieChart {...pieChartData} screenSize={DESKTOP} />
          </PieChartContainer>
          <TooltipContainer>
            {pieChartData.tooltipData.map(data => generateEntry(data))}
          </TooltipContainer>
        </Flex>
      </ExpandedContent>
    </Collapse>
  </Container>
);

DesktopVersion.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemValue: PropTypes.number.isRequired,
  formattedValue: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  screenSize: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  barColor: PropTypes.string.isRequired,
  barChartData: PropTypes.instanceOf(Object).isRequired,
  pieChartData: PropTypes.instanceOf(Object),
  generateEntry: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  statsExpand: PropTypes.bool.isRequired,
  saleOverTimeExpand: PropTypes.bool.isRequired,
  breakDownExpand: PropTypes.bool.isRequired,
  handleStatsExpand: PropTypes.func.isRequired,
  handleSaleOverTimeExpand: PropTypes.func.isRequired,
  handleBreakDownExpand: PropTypes.func.isRequired,
  maxValue: PropTypes.number.isRequired,
  statsData: PropTypes.instanceOf(Object).isRequired,
};

DesktopVersion.defaultProps = {
  pieChartData: [],
};

export default DesktopVersion;
