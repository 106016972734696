import { connect } from 'react-redux';

import { getItemStats } from '../../../modules/statistics';
import { getPieChartData } from '../../../helpers/charts';
import { PieChart } from '../../shared';

export const mapStateToProps = (state) => {
  const itemStats = getItemStats(state);
  return {
    chartTitle: 'Top Items',
    iconName: 'item',
    seeMoreData: {
      path: '/items',
      label: 'ITEMS',
    },
    ...getPieChartData(itemStats),
  };
};

export default connect(mapStateToProps)(PieChart);
