import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles';
import NavigationDropdownContainer from './NavigationDropdownContainer';
import DateRangeSelectorContainer from './DateRangeSelectorContainer';
import DateIncrementDropdownContainer from './DateIncrementDropdownContainer';

const HeaderBarStyled = styled.section`
  color: ${styles.colors.white};
  text-align: center;
  grid-column: start / end;
  grid-row: start / header;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderBar = ({ screenSize }) => (
  <HeaderBarStyled className={`header-bar ${screenSize}`}>
    <NavigationDropdownContainer />
    <DateRangeSelectorContainer />
    <DateIncrementDropdownContainer />
  </HeaderBarStyled>
);

HeaderBar.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

HeaderBar.defaultProps = {};

export default HeaderBar;
