import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import DashboardContainer from './features/dashboard/DashboardContainer';
import SalesTrendsContainer from './features/sales-trends/SalesTrendsContainer';
import EmployeesContainer from './features/employees/EmployeesContainer';
import ItemsContainer from './features/items/ItemsContainer';
import {AfterLogin} from "./features/AfterLogin";


const Main = () => (
  <Switch>
    <Route exact path="/" component={DashboardContainer} />
    <Route path="/sales-trends" component={SalesTrendsContainer} />
    <Route path="/employees" component={EmployeesContainer} />
    <Route path="/items" component={ItemsContainer} />
    <Route path="/logged-in" component={AfterLogin}/>
  </Switch>
);

export default withRouter(Main);
