import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import Spinner from '../Spinner';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
};

class Loader extends React.Component {
  static propTypes = {
    isOrdersFetching: PropTypes.bool.isRequired,
    isSalesTrendsFetching: PropTypes.bool.isRequired,
  };

  state = { displayLoader: false };

  componentDidUpdate(prevProps) {
    const { isOrdersFetching, isSalesTrendsFetching } = this.props;
    if ((prevProps.isOrdersFetching !== isOrdersFetching)
     || (prevProps.isSalesTrendsFetching !== isSalesTrendsFetching)) {
      this.disableLoader();
      this.timer = setTimeout(this.enableLoader, 250);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableLoader = () => {
    this.setState({ displayLoader: true });
  };

  disableLoader = () => {
    this.setState({ displayLoader: false });
  };

  render() {
    const { isOrdersFetching, isSalesTrendsFetching } = this.props;
    const { displayLoader } = this.state;

    const open = isOrdersFetching || isSalesTrendsFetching;

    return (
      displayLoader
      && (
        <Modal
          disableAutoFocus
          open={open}
        >
          <Spinner style={styles} />
        </Modal>
      )
    );
  }
}

export default Loader;
