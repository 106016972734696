import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getScreenSize, setTab } from '../../modules/core';
import { getRangeFilter, getCurrentDate } from '../../modules/filter';
import { getItemStats, fetchItemStats } from '../../modules/statistics';
import Items from './Items';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  rangeFilter: getRangeFilter(state),
  currentDate: getCurrentDate(state),
  listData: getItemStats(state),
});

const mapDispatchToProps = {
  setTab,
  fetchItemStatsWithFilter: fetchItemStats,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Items));
