import { rangeValues } from '../constants';

let defaultLocale = 'en-US';
const defaultCurrency = { style: 'currency', currency: 'USD' };
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const isDay = filter => filter === rangeValues.DAY;
const isWeek = filter => filter === rangeValues.WEEK;
const isMonth = filter => filter === rangeValues.MONTH;
const isYear = filter => filter === rangeValues.YEAR;

// Year helpers
const setNextYear = date => date.setFullYear(date.getFullYear() + 1);
const setPreviousYear = date => date.setFullYear(date.getFullYear() - 1);
const getFullYear = date => new Date(date).getFullYear();
const getFirstDayOfYear = date => new Date(getFullYear(date), 0, 1);
const getLastDayOfYear = date => new Date(getFullYear(date), 11, 31);

// Month helpers
const getFirstDayOfMonth = date => new Date(date.getFullYear(), date.getMonth(), 1);
const getLastDayOfMonth = date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
const getCurrentMonth = date => date.toLocaleString(defaultLocale, { month: 'short' });
const setNextMonth = date => date.setMonth(date.getMonth() + 1);
const setPreviousMonth = date => date.setMonth(date.getMonth() - 1);

// Week helpers
const getFirstDayOfWeek = date => new Date(date.setDate(date.getDate() - date.getDay()));
const getLastDayOfWeek = date => new Date(date.setDate(date.getDate() - date.getDay() + 6));
const setNextWeek = date => date.setDate(date.getDate() + 7);
const setPreviousWeek = date => date.setDate(date.getDate() - 7);

// Day helpers
const setNextDay = date => date.setDate(date.getDate() + 1);
const setPreviousDay = date => date.setDate(date.getDate() - 1);
const getStartOfDay = date => new Date(date.setHours(0, 0, 0, 0));
const getEndOfDay = date => new Date(date.setHours(23, 59, 59, 999));
const getNumberOfDays = date => getLastDayOfMonth(date).getDate();

const defaultDateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
const formattedDay = day => day.toLocaleDateString(defaultLocale, defaultDateOptions);
const formattedOrderTotal = total => total.toLocaleString(defaultLocale, defaultCurrency);
const setLocale = (locale) => { defaultLocale = locale; };

const getFilter = (props) => {
  const { currentDate, rangeFilter } = props;
  const date = new Date();
  let startTime;
  let endTime;
  if (isMonth(rangeFilter)) {
    startTime = getFirstDayOfMonth(getStartOfDay(currentDate));
    endTime = getEndOfDay(getLastDayOfMonth(currentDate));
  } else if (isWeek(rangeFilter)) {
    startTime = getStartOfDay(getFirstDayOfWeek(currentDate));
    endTime = getEndOfDay(getLastDayOfWeek(currentDate));
  } else if (isDay(rangeFilter)) {
    startTime = getStartOfDay(currentDate);
    endTime = getEndOfDay(currentDate);
  } else if (isYear(rangeFilter)) {
    startTime = getFirstDayOfYear(getStartOfDay(currentDate));
    endTime = date.getFullYear === getFullYear(currentDate)
      ? getEndOfDay(currentDate)
      : getEndOfDay(getLastDayOfYear(currentDate));
  }

  return { startTime: Number(startTime), endTime: Number(endTime) };
};

export {
  months,
  isDay,
  isWeek,
  isMonth,
  isYear,
  getFullYear,
  getFirstDayOfYear,
  getLastDayOfYear,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getStartOfDay,
  getEndOfDay,
  getCurrentMonth,
  setNextMonth,
  setPreviousMonth,
  getNumberOfDays,
  setNextWeek,
  setPreviousWeek,
  setNextDay,
  setPreviousDay,
  setNextYear,
  setPreviousYear,
  formattedDay,
  formattedOrderTotal,
  setLocale,
  getFilter,
};
