import styled from 'styled-components';
import styles from '../../styles';

const StatValue = styled.div`
  align-items: flex-start;
  color: ${styles.colors.white};
  font-size: ${styles.typography.sizes.larger}px;
  font-family: ${styles.typography.fonts.openSans};
  font-weight: ${styles.typography.weights.light};
`;

export default StatValue;
