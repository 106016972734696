import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';
import styles from '../../styles';
import Icon from '../shared/Icon';
import Mixpanel from '../../utils/mixpanel';
import { subscriptionTiers } from '../../constants';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getFirstDayOfYear,
  getLastDayOfYear,
  formattedDay,
  setNextMonth,
  setPreviousMonth,
  setNextDay,
  setPreviousDay,
  setNextWeek,
  setPreviousWeek,
  setNextYear,
  setPreviousYear,
  isDay,
  isWeek,
  isMonth,
  isYear,
  getFullYear,
} from '../../helpers/dateRange';

const DateRangeSelectorStyled = styled.div`
  flex: 0 1 auto;
  display: grid;
  width: 500px;
  grid-template-columns: [start] 1fr repeat(6, 1fr) [end] 1fr;
  align-items: center;
  &.mobile {    
    width: 200px;
    padding: 0;
    grid-template-columns: [start] 1fr repeat(3, 1fr) [end] 1fr;
  }
`;

const BackBtn = styled.span`
  grid-column: start;
  justify-self: end;
`;

const ForwardBtn = styled.span`
  grid-column: end;
  justify-self: start;
`;

const DateRange = styled.div`
  grid-column: 2 / 8;
  font-size: ${styles.typography.sizes.hero}px;
  font-weight: ${styles.typography.weights.regular};
  
  &.mobile {
    grid-column: 2 / 5;
    font-size: ${styles.typography.sizes.small}px;
    font-weight: ${styles.typography.weights.regular};    
  }
`;

const renderDateRange = (date, screenSize, filter) => {
  const renderFirstDayLastDay = (firstDay, lastDay) => (
    <DateRange className={`date-range ${screenSize}`}>
      { screenSize === 'mobile'
        ? (
          <div>
            <div>{formattedDay(firstDay)}</div>
            <div>{formattedDay(lastDay)}</div>
          </div>
        )
        : `${formattedDay(firstDay)} - ${formattedDay(lastDay)}`
    }
    </DateRange>
  );
  if (isMonth(filter) || isWeek(filter)) {
    const firstDay = isMonth(filter) ? getFirstDayOfMonth(date) : getFirstDayOfWeek(date);
    const lastDay = isMonth(filter) ? getLastDayOfMonth(date) : getLastDayOfWeek(date);
    return renderFirstDayLastDay(firstDay, lastDay);
  }
  if (isYear(filter)) {
    const firstDay = getFirstDayOfYear(date);
    const currentDate = new Date();
    const lastDay = currentDate.getFullYear() === getFullYear(date)
      ? currentDate
      : getLastDayOfYear(date);
    return renderFirstDayLastDay(firstDay, lastDay);
  }
  return (
    <DateRange className={`date-range ${screenSize}`}>
      { formattedDay(date)}
    </DateRange>
  );
};

const handleBackBtn = debounce((
  filter,
  setDate,
  currentDate,
  setModalState,
  billingStatus,
) => {
  Mixpanel.track('Time Back', {});
  if (billingStatus === subscriptionTiers.FREE) {
    setModalState(true);
  } else {
    if (isMonth(filter)) {
      const previousMonth = setPreviousMonth(currentDate);
      setDate(previousMonth);
    }
    if (isWeek(filter)) {
      const previousWeek = setPreviousWeek(currentDate);
      setDate(previousWeek);
    }
    if (isDay(filter)) {
      const previousDay = setPreviousDay(currentDate);
      setDate(previousDay);
    }
    if (isYear(filter)) {
      const previousYear = setPreviousYear(currentDate);
      setDate(previousYear);
    }
  }
}, 200);

const handleForwardBtn = debounce((
  filter,
  setDate,
  currentDate,
  setModalState,
  billingStatus,
) => {
  Mixpanel.track('Time Forward', {});
  if (billingStatus === subscriptionTiers.FREE) {
    setModalState(true);
  } else {
    if (isMonth(filter)) {
      const nextMonth = setNextMonth(currentDate);
      setDate(nextMonth);
    }
    if (isWeek(filter)) {
      const nextWeek = setNextWeek(currentDate);
      setDate(nextWeek);
    }
    if (isDay(filter)) {
      const nextDay = setNextDay(currentDate);
      setDate(nextDay);
    }
    if (isYear(filter)) {
      const nextYear = setNextYear(currentDate);
      setDate(nextYear);
    }
  }
}, 200);

const DateRangeSelector = ({
  screenSize,
  currentDate,
  setDate,
  filter,
  setModalState,
  billingStatus,
}) => (
  <DateRangeSelectorStyled className={`date-range-selector ${screenSize}`}>
    <BackBtn onClick={() => handleBackBtn(filter, setDate, currentDate, setModalState, billingStatus)}><Icon name="left" /></BackBtn>
    { renderDateRange(currentDate, screenSize, filter) }
    <ForwardBtn onClick={() => handleForwardBtn(filter, setDate, currentDate, setModalState, billingStatus)}><Icon name="right" /></ForwardBtn>
  </DateRangeSelectorStyled>
);

DateRangeSelector.propTypes = {
  screenSize: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  billingStatus: PropTypes.string.isRequired,
};

export default DateRangeSelector;
