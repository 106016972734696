import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { subscriptionTiers } from '../../constants';
import { isMobile } from '../../utils/environment';
import { GradientButton } from '.';

const adsVersions = [
  {
    header: 'Curious about your daily, weekly, and monthly sales trends?',
    text: 'Upgrade to our $4.95/month tier to get access to Sales Trends, Year-to-Date Reporting, and more features coming soon.',
  },
  {
    header: 'How much did you sell this year?',
    text: 'Upgrade to our $4.95/month tier to get access to Year-to-Date Reporting, Sales Trends, and more.',
  },
];

const getRandomIdx = max => Math.floor(Math.random() * Math.floor(max));

const Container = styled.div`
  height: 126px;
  margin: auto;
  width: 95%;
  &.desktop {
    width: 70%;
  }
  max-width: 945px;
  padding: 100px 0;
`;

const GridContainer = styled.div`  
  height: 100%;
  width: 100%;  
  display: grid;
  grid: 1fr 2fr / 100%;
  justify-items: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${styles.colors.lighterGray};
`;

const Header = styled.div`
  padding: 20px 0 10px 0;
  &.desktop{
    font-size: ${styles.typography.sizes.mediumLarger}px;
  }
  &.tablet {
    font-size: ${styles.typography.sizes.mediumLarge}px;
  }
  &.mobile {
    font-size: ${styles.typography.sizes.small}px;
  }
  font-family: ${styles.typography.fonts.openSans};
  color: ${styles.colors.lightBlue};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.desktop {
    padding: 0 100px 20px 100px; 
  }
  &.tablet {
    padding: 0 35px 26px 35px;
  }
  &.mobile {
    padding: 0 25px 26px 25px;
  }  
`;

const Text = styled.div`
  font-weight: ${styles.typography.weights.light};
  &.desktop {
    font-size: ${styles.typography.sizes.normal}px;
  }
  &.tablet {
    font-size: ${styles.typography.sizes.small}px;
  }
  &.mobile {
    font-size: ${styles.typography.sizes.smaller}px;
  }  
  color: ${styles.colors.lightGray};
  width: 70%;
`;

const ButtonWrapper = styled.div`
  width: 30%;
  display: flex;
  flex: 0 0 225px;
  &.mobile {
    flex: 0 1 auto;
  }  
  padding-left: 10px;
  align-items: center;
`;

const AdsPanel = ({ screenSize, billingStatus }) => {
  const ads = adsVersions[getRandomIdx(2)];
  return (
    billingStatus === subscriptionTiers.FREE && (
    <Container className={`ads ${screenSize}`}>
      <GridContainer>
        <Header className={`ads header ${screenSize}`}>{ads.header}</Header>
        <Content className={`ads content ${screenSize}`}>
          <Text className={`ads text ${screenSize}`}>
            {ads.text}
          </Text>
          <ButtonWrapper className={`ads button ${screenSize}`}>
            <GradientButton buttonText={isMobile(screenSize) ? 'Upgrade' : 'Upgrade Dashboard'} />
          </ButtonWrapper>
        </Content>
      </GridContainer>
    </Container>
    ));
};

AdsPanel.propTypes = {
  screenSize: PropTypes.string.isRequired,
  billingStatus: PropTypes.string.isRequired,
};

export default AdsPanel;
