import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const options = {
  cornerRadius: 50,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 10,
      bottom: 15,
    },
  },
  tooltips: {
    enabled: false,
  },
  scales: {
    xAxes: [{
      barThickness: 4,
      gridLines: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    }],
    yAxes: [{
      display: false,
    }],
  },
};

const SmallBarChart = ({ data }) => (
  <div>
    <Bar
      width={100}
      height={100}
      data={data}
      options={options}
    />
  </div>
);

SmallBarChart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};


export default SmallBarChart;
