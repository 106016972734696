import { fetchOrders } from '../utils/api';
import { redirectToCloverAuth, redirectToAuth } from '../utils/authentication';
import {
  FETCH_ORDERS,
  setOrders,
  setOrdersErr,
} from '../modules/order';
import { removeUnconfirmedOrders } from '../helpers/charts';

export const fetchOrdersWithDispatch = async (dispatch, filter) => {
  try {
    const { orders } = await fetchOrders(filter);
    const cleanOrders = removeUnconfirmedOrders(orders);
    dispatch(setOrders(cleanOrders));
  } catch (err) {
    dispatch(setOrdersErr(err));
    const url = new URL(window.location.href).searchParams;
    const code = url.get('code');
    const merchantId = url.get('merchant_id');
    const clientId = url.get('client_id');
    if (code) {
      redirectToAuth(merchantId, clientId, code);
    } else {
      redirectToCloverAuth(merchantId);
    }
  }
};

const OrderMiddleware = ({ dispatch }) => next => (action) => {
  switch (action.type) {
    case FETCH_ORDERS: {
      fetchOrdersWithDispatch(dispatch, action.filter);
      break;
    }
    default:
  }
  return next(action);
};


export default OrderMiddleware;
