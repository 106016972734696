import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';

import { pieChartColors } from '../../../constants';
import { formattedOrderTotal } from '../../../helpers/dateRange';
import { handleOverflow, maxTooltipLengthFromScreenSize } from './utils';
import { isDesktop } from '../../../utils/environment';
import LinearProgressBar from '../LinearProgressBar';

const ContainerStyled = styled.div`
  display: ${props => (props.show ? 'inline' : 'none')}
  background-color: ${styles.colors.white};
  position: absolute;
  bottom: 110px;
  left: ${({ screenSize }) => (isDesktop(screenSize) ? 120 : 80)}px;
  padding: 0px 24px;
  -webkit-box-shadow: 2px 3px 1px ${styles.colors.black};
  -moz-box-shadow: 2px 3px 1px ${styles.colors.black};
  box-shadow: 8px 8px 8px ${styles.colors.black};
  z-index: 3;
`;

const Wrapper = styled.div`
  display: flex;
  height: 24px;
  padding-top: 12px;
`;

const Title = styled.div`
  font-size: ${styles.typography.sizes.medium}px;
  font-weight: ${styles.typography.weights.light};
  color: ${styles.colors.black};
  padding-top: 7px;
  text-align: center;
`;

const Rank = styled.div`
  font-size: ${styles.typography.sizes.hero}px;
  font-weight: ${styles.typography.weights.light};
  color: ${styles.colors.lightGray};
  text-align: right;
  margin-top: -6px;
  padding-right: 5px;
`;

const Flex = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const Name = styled.div`
  width: ${({ screenSize }) => (isDesktop(screenSize) ? 170 : 100)}px;
  height: 16px;
  font-size: ${styles.typography.sizes.small}px;
  color: ${styles.colors.black};
`;

const Value = styled.div`
  width: 48px;
  font-size: ${styles.typography.sizes.smaller}px;
  text-align: right;
  color: ${styles.colors.lightGray};
`;

const SeeMore = styled.div`
  font-size: ${styles.typography.sizes.smaller}px;
  font-weight: ${styles.typography.weights.medium};
  color: ${styles.colors.lightBlue}
  text-align: center;
  padding: 15px 0px 0px;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: relative;
  bottom: -8px;
  border-top: 8px solid ${styles.colors.white};
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 75px;
`;

const generateEntry = (data, maxValue, screenSize) => {
  const [rank, name, value, percentage] = data;
  return (
    <Wrapper key={name}>
      <Rank>{rank + 1}</Rank>
      <div>
        <Flex>
          <Name screenSize={screenSize}>{handleOverflow(name, maxTooltipLengthFromScreenSize(screenSize))}</Name>
          <Value>{formattedOrderTotal(value / 100)}</Value>
        </Flex>
        <LinearProgressBar
          barColor={pieChartColors[rank]}
          value={percentage}
          maxValue={maxValue}
        />
      </div>
    </Wrapper>
  );
};

const Tooltip = (props) => {
  const {
    title,
    data,
    show,
    seeMoreLink,
    screenSize,
    maxValue,
  } = props;
  return (
    <ContainerStyled screenSize={screenSize} show={show}>
      <Title>{title}</Title>
      {data && data.map(dataArr => generateEntry(dataArr, maxValue, screenSize))}
      <SeeMore onClick={seeMoreLink}>SEE MORE</SeeMore>
      <ArrowDown />
    </ContainerStyled>
  );
};

Tooltip.propTypes = {
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  seeMoreLink: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

Tooltip.defaultProps = {
  data: [],
};

export default Tooltip;
