import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';
import { StatLabel, StatValue } from '../../shared';

/* eslint react/jsx-one-expression-per-line: 0 */

const Container = styled.section`
  margin-top: 32px;
  background-color: ${styles.colors.darkBlue};
  display: grid;
  flex-wrap: wrap;
  &.desktop {
    grid-template-columns: [start] repeat(12, 1fr) [end];
    height: 168px;
    .sales, .employee {
      grid-column: 3 / 5;
    }
    .orders, .customer {
      grid-column: 6 / 8;
    }
    .oph, .hour {
      grid-column: 9 / 11;
    }
  }
  &.tablet {
    grid-template-columns: [start] repeat(6, 1fr) [end];
    height: 232px;
    .sales, .oph, .customer {
      grid-column: 2 / 4;
      margin-left: -38px;
    }
    .orders, .employee, .hour {
      grid-column: 4 / 7;
      padding-left: 52px;
    }
  }
  &.mobile {
    grid-template-columns: [start] repeat(3, 1fr) [end];
    height: 352px;
    .sales, .orders, .oph, .employee, .customer, .hour {
      grid-column: 2 / end;
      margin-left: -55px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Statistics = ({
  screenSize,
  totalSales,
  totalOrders,
  ordersPerHour,
  perEmployee,
  perCustomer,
  perHour,
}) => (
  <Container className={`stats ${screenSize}`}>
    <Wrapper className="sales">
      <StatLabel>Total Sales</StatLabel>
      <StatValue>{totalSales}</StatValue>
    </Wrapper>
    <Wrapper className="orders">
      <StatLabel>Total Orders</StatLabel>
      <StatValue>{totalOrders}</StatValue>
    </Wrapper>
    <Wrapper className="oph">
      <StatLabel>Orders per<br />Hour</StatLabel>
      <StatValue>{ordersPerHour}</StatValue>
    </Wrapper>
    <Wrapper className="employee">
      <StatLabel>Sales per<br />Employee</StatLabel>
      <StatValue>{perEmployee}</StatValue>
    </Wrapper>
    <Wrapper className="customer">
      <StatLabel>Sales per<br />Customer</StatLabel>
      <StatValue>{perCustomer}</StatValue>
    </Wrapper>
    <Wrapper className="hour">
      <StatLabel>Sales per Hour</StatLabel>
      <StatValue>{perHour}</StatValue>
    </Wrapper>
  </Container>
);

Statistics.propTypes = {
  ordersPerHour: PropTypes.string.isRequired,
  perCustomer: PropTypes.string.isRequired,
  perEmployee: PropTypes.string.isRequired,
  perHour: PropTypes.string.isRequired,
  screenSize: PropTypes.string.isRequired,
  totalOrders: PropTypes.number.isRequired,
  totalSales: PropTypes.string.isRequired,
};

export default Statistics;
