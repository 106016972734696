import keymirror from 'keymirror';
import styles from '../styles';

const rangeValues = keymirror({
  DAY: null,
  WEEK: null,
  MONTH: null,
  YEAR: null,
});

const MAX_DAY = 24;
const MAX_WEEK = 7;
const MAX_MONTH = 31;
const MAX_YEAR = 12;

const tabValues = keymirror({
  DASHBOARD: null,
  SALESTRENDS: null,
  EMPLOYEES: null,
  ITEMS: null,
  SIGNIN: null,
  SUPPORT: null,
});

const menuOptions = [{
  name: 'Dashboard',
  label: tabValues.DASHBOARD,
  path: '/',
}, {
  name: 'Sales Trends',
  label: tabValues.SALESTRENDS,
  path: '/sales-trends',
}, {
  name: 'Employees',
  label: tabValues.EMPLOYEES,
  path: '/employees',
}, {
  name: 'Items',
  label: tabValues.ITEMS,
  path: '/items',
}];

const pieChartColors = [
  styles.colors.red,
  styles.colors.orange,
  styles.colors.yellow,
  styles.colors.cyan,
  styles.colors.lightBlue,
  styles.colors.blue,
  styles.colors.purple,
];

const subscriptionTiers = keymirror({
  FREE: null,
  PAID: null,
});

const {
  REACT_APP_MIXPANEL_KEY,
  REACT_APP_MIXPANEL_ENABLED,
  REACT_APP_APP_VERSION,
} = process.env;

const API_HOST = process.env.REACT_APP_API_HOST;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID;
const CLOVER_APP_LINK = process.env.REACT_APP_CLOVER_APP_LINK;

export {
  rangeValues,
  MAX_DAY,
  MAX_WEEK,
  MAX_MONTH,
  MAX_YEAR,
  tabValues,
  menuOptions,
  pieChartColors,
  subscriptionTiers,
  API_HOST,
  AUTH_TOKEN,
  MERCHANT_ID,
  REACT_APP_MIXPANEL_KEY,
  REACT_APP_MIXPANEL_ENABLED,
  REACT_APP_APP_VERSION,
  CLOVER_APP_LINK,
};
