import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';
import Mixpanel from '../../../utils/mixpanel';
import ReusableModal from '../../shared/Modal';
import { CLOVER_APP_LINK } from '../../../constants';

const ModalTextStyled = styled.p`
  text-align: left;
  font-size: ${styles.typography.sizes.medium}px;
  font-weight: ${styles.typography.weights.light};
`;


class UpgradeModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;
    if (isVisible && isVisible !== prevProps.isVisible) {
      Mixpanel.track('Upgrade Modal Launched', {});
    }
  }

  onHandleClose = () => {
    const { onClose } = this.props;
    onClose();
    Mixpanel.track('Upgrade Modal: Tap X', {});
  }

  render() {
    const {
      isVisible,
      screenSize,
      desktopWidth,
      modalTitle,
      buttonText,
    } = this.props;
    return (
      <ReusableModal
        modalVisibility={isVisible}
        screenSize={screenSize}
        desktopWidth={desktopWidth}
        modalTitle={modalTitle}
        buttonText={buttonText}
        onClose={this.onHandleClose}
        href={CLOVER_APP_LINK}
      >
        <div>
          <ModalTextStyled>
            We’ve made major improvements to Dashboard!
            <br />
            {' '}
    These updates include:
          </ModalTextStyled>
          <ModalTextStyled>
            ● Year-to-Date Reporting - you can now see data for the current year and previous years.
          </ModalTextStyled>
          <ModalTextStyled>
            ● Sales Trends - compare your sales on this day, week, or month to previous timeframes.
          </ModalTextStyled>
          <ModalTextStyled>
            ● Improved Performance - experience much faster load times.
          </ModalTextStyled>
          <ModalTextStyled>
            Upgrade to our $4.95/month tier to get all of these improvements plus
             an ad-free experience and more features coming soon.
          </ModalTextStyled>
        </div>
      </ReusableModal>
    );
  }
}

UpgradeModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  desktopWidth: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  screenSize: PropTypes.string.isRequired,
};

export default UpgradeModal;
