import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles';

import { pieChartColors, tabValues } from '../../constants';
import { getFilter } from '../../helpers/dateRange';
import { getPieChartData } from '../../helpers/charts';
import { Title, HeaderPanel, CollapsiblePanel } from '../shared';
import { getOverallPercent } from '../../helpers/statistics';
import Mixpanel from '../../utils/mixpanel';

const ItemsStyled = styled.section`
  grid-row: header / end;
  color: ${styles.colors.white};
  background-color: ${styles.colors.black};
  &.desktop {
    grid-column: 4 / 10;
  }
  &.tablet {
    grid-column: start / end;
    padding: 0 50px 0 50px;

  } &.mobile {
    grid-column: start / end;
    padding: 8px;
  }
`;

const normalizePieChartPercentages = (listData) => {
  if (!listData) { return listData; }

  const sumTotalSales = (totalSales, employee) => totalSales + employee.totalSales;
  for (let i = 0; i < listData.length; i++) {
    const totalSales = listData[i].employees.reduce(sumTotalSales, 0);
    for (const employee of listData[i].employees) {
      employee.overallSales = getOverallPercent(employee.totalSales, totalSales);
    }
  }

  return listData;
};

class Items extends React.Component {
  state = {
    selectedRowId: 0,
  };

  componentDidMount() {
    const { setTab, fetchItemStatsWithFilter } = this.props;
    setTab(tabValues.ITEMS);

    const filter = getFilter(this.props);
    fetchItemStatsWithFilter(filter);
    Mixpanel.track('Navigation: Items View', {});
  }

  componentDidUpdate(prevProps) {
    const {
      currentDate,
      rangeFilter,
      fetchItemStatsWithFilter,
    } = this.props;

    if (currentDate !== prevProps.currentDate || rangeFilter !== prevProps.rangeFilter) {
      const filter = getFilter(this.props);
      fetchItemStatsWithFilter(filter);
    }
  }

  setSelectedRowId = id => this.setState({ selectedRowId: id });

  render() {
    const {
      screenSize, listData, rangeFilter, currentDate,
    } = this.props;
    const { selectedRowId } = this.state;
    const maxValue = listData[0] ? listData[0].totalSales : 0;

    const normalizedListData = normalizePieChartPercentages(listData);

    return (
      <ItemsStyled className={`items ${screenSize}`}>
        <Title screenSize={screenSize}>ITEMS</Title>
        <HeaderPanel screenSize={screenSize} breakDown="employees" />
        {
          normalizedListData.map((item, i) => (
            <CollapsiblePanel
              key={item.name}
              rank={i + 1}
              itemName={item.name}
              itemValue={item.totalSales}
              maxValue={maxValue}
              barColor={pieChartColors[i]}
              barChartData={item.bargraphData}
              pieChartData={getPieChartData(item.employees)}
              screenSize={screenSize}
              rangeFilter={rangeFilter}
              currentDate={currentDate}
              setSelectedRowId={this.setSelectedRowId}
              selectedRowId={selectedRowId}
              statsData={item}
              mixpanelSectionName="Items"
              mixpanelFirstSectionEvent="Sales Details"
              mixpanelSecondSectionEvent="Sales/Time Details"
              mixpanelThirdSectionEvent="Employees Details"
            />
          ))
        }
      </ItemsStyled>
    );
  }
}

Items.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  fetchItemStatsWithFilter: PropTypes.func.isRequired,
  listData: PropTypes.instanceOf(Object).isRequired,
  rangeFilter: PropTypes.string.isRequired,
  screenSize: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default Items;
