/**
 * Filter Module
 *
 * Responsible for handling the Order Filter
 *
 */
import { createSelector } from 'reselect';
import { ActionsHelper } from '../helpers';
import { rangeValues } from '../constants';

export const initialState = {
  rangeFilter: rangeValues.DAY,
  dateOffsetFilter: 0,
  currentDate: new Date(),
};

// Action Types
export const SET_RANGE_FILTER = 'SET_RANGE_FILTER';
export const SET_DATE_OFFSET_FILTER = 'SET_DATE_OFFSET_FILTER';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';

// Action Creators
export const setRangeFilter = ActionsHelper.createBasicStateAction(SET_RANGE_FILTER);
export const setDateOffsetFilter = ActionsHelper.createBasicStateAction(SET_DATE_OFFSET_FILTER);
export const setCurrentDate = ActionsHelper.createBasicStateAction(SET_CURRENT_DATE);

// Selectors
export const getFilter = state => state.filter;
export const getRangeFilter = createSelector(getFilter, state => state.rangeFilter);
export const getCurrentDate = createSelector(getFilter, state => state.currentDate);

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RANGE_FILTER:
      return { ...state, rangeFilter: action.state };
    case SET_DATE_OFFSET_FILTER:
      return { ...state, dateOffsetFilter: action.state };
    case SET_CURRENT_DATE:
      return { ...state, currentDate: new Date(action.state) };
    default:
      return state;
  }
}
