export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';
export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';
export const DEV = 'development';
export const PROD = 'production';
export const STAGING = 'staging';

export const isMobile = size => size === MOBILE;
export const isTablet = size => size === TABLET;
export const isDesktop = size => size === DESKTOP;
export const isPortrait = orientation => orientation === PORTRAIT;
export const isLandscape = orientation => orientation === LANDSCAPE;

// Measure in px
const maxScreenSizeBreakpoint = {
  mobile: 480,
  tablet: 768,
};

// There is no internal browser support for managing orientation
export const getScreenOrientationFromWindow = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  if (width <= height) {
    return PORTRAIT;
  }

  return LANDSCAPE;
};

export const getScreenSizeFromWindow = () => {
  const { mobile, tablet } = maxScreenSizeBreakpoint;
  let windowSize;

  /* eslint-disable no-unused-expressions */
  PORTRAIT === getScreenOrientationFromWindow()
    ? windowSize = window.innerWidth
    : windowSize = window.innerHeight;
  if (windowSize <= mobile) return MOBILE;
  if (windowSize > mobile && windowSize <= tablet) return TABLET;

  return DESKTOP;
};


export default {
  getScreenSizeFromWindow,
  getScreenOrientationFromWindow,
};
