import { fetch } from '../utils/api';
import {
  FETCH_EMPLOYEE_STATS,
  setEmployeeStats,
  FETCH_ITEM_STATS,
  setItemStats,
  setStatsError,
} from '../modules/statistics';

const fetchEmployeeStats = filter => fetch('/employeeStatistics', { ...filter });
const fetchItemStats = filter => fetch('/itemStatistics', { ...filter });

export const fetchEmployeeStatsWithDispatch = async (dispatch, filter) => {
  try {
    const stats = await fetchEmployeeStats(filter);
    dispatch(setEmployeeStats(stats));
  } catch (err) {
    dispatch(setStatsError(err));
  }
};

export const fetchItemStatsWithDispatch = async (dispatch, filter) => {
  try {
    const stats = await fetchItemStats(filter);
    dispatch(setItemStats(stats));
  } catch (err) {
    dispatch(setStatsError(err));
  }
};

const StatsMiddleware = ({ dispatch }) => next => (action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_STATS: {
      fetchEmployeeStatsWithDispatch(dispatch, action.filter);
      break;
    }
    case FETCH_ITEM_STATS: {
      fetchItemStatsWithDispatch(dispatch, action.filter);
      break;
    }
    default:
  }
  return next(action);
};


export default StatsMiddleware;
