import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getScreenSize, setTab } from '../../modules/core';
import { getRangeFilter, getCurrentDate } from '../../modules/filter';
import { getEmployeeStats, fetchEmployeeStats } from '../../modules/statistics';
import Employees from './Employees';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  rangeFilter: getRangeFilter(state),
  currentDate: getCurrentDate(state),
  listData: getEmployeeStats(state),
});

const mapDispatchToProps = {
  setTab,
  fetchEmployeeStatsWithFilter: fetchEmployeeStats,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Employees));
