import { combineReducers } from 'redux';
import {
  order, salesTrends, core, filter, statistics, metrics,
} from '../modules';

export default combineReducers({
  core,
  filter,
  order,
  salesTrends,
  statistics,
  metrics,
});
