import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Done from '@material-ui/icons/Done';
import Icon from '../shared/Icon';
import { rangeValues, subscriptionTiers } from '../../constants';
import styles from '../../styles';
import { menuItemStyle, ArrowUp } from './NavigationDropdown';
import { isDesktop } from '../../utils/environment';
import Mixpanel from '../../utils/mixpanel';

import {
  isDay, isWeek, isMonth, isYear,
} from '../../helpers/dateRange';

const DateIncrementDropDownStyled = styled.div`
  flex: 0.1 1 auto;
`;

const RangeTextStyled = styled.span`
  font-family: ${styles.typography.fonts.openSans};
  font-size: ${styles.typography.sizes.normal}px;
`;

const IconStyled = styled(Icon)`
  padding-left: 20px;
`;

const ArrowUpStyled = styled(ArrowUp)`
  margin-left: ${props => (isDesktop(props.screenSize) ? 65 : 0)}px;
`;

class RangeDropdown extends React.PureComponent {
  static propTypes = {
    rangeFilter: PropTypes.string.isRequired,
    select: PropTypes.func.isRequired,
    screenSize: PropTypes.string.isRequired,
    billingStatus: PropTypes.string.isRequired,
    setModalState: PropTypes.func.isRequired,
  };

  state = {
    anchorEl: null,
    showArrow: false,
  };

  handleMenuClick = event => this.setState({ anchorEl: event.currentTarget, showArrow: true });

  handleMenuItemClick = (event, option) => {
    const { select, billingStatus, setModalState } = this.props;
    if (billingStatus === subscriptionTiers.FREE && option === rangeValues.YEAR) {
      setModalState(true);
    } else {
      select(option);
      Mixpanel.track('Timeframe Changed', { timeframe: option });
    }
    this.handleClose();
  };

  handleClose = () => this.setState({ anchorEl: null, showArrow: false });

  formatSmallScreenRangeText = (text) => {
    let formattedText;
    if (isDay(text)) {
      formattedText = 'DY';
    }
    if (isWeek(text)) {
      formattedText = 'WK';
    }
    if (isMonth(text)) {
      formattedText = 'MO';
    }
    if (isYear(text)) {
      formattedText = 'YR';
    }
    return formattedText;
  };

  render() {
    const { anchorEl, showArrow } = this.state;
    const { rangeFilter, screenSize } = this.props;

    return (
      <DateIncrementDropDownStyled className="date-increment-dropdown">
        <Button
          aria-label="Date Range Filter"
          aria-owns={anchorEl ? 'date-range-filter' : null}
          aria-haspopup
          color="inherit"
          onClick={this.handleMenuClick}
        >
          <RangeTextStyled>
            {
              isDesktop(screenSize)
                ? rangeFilter
                : this.formatSmallScreenRangeText(rangeFilter)
            }
          </RangeTextStyled>
          { isDesktop(screenSize) && <IconStyled name="down" /> }
        </Button>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition>
          { showArrow ? <ArrowUpStyled screenSize={screenSize} /> : <div />}
          <Menu
            id="date-range-filter"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {Object.keys(rangeValues).map(option => (
              <MenuItem
                style={menuItemStyle}
                key={option}
                selected={option === rangeFilter}
                onClick={event => this.handleMenuItemClick(event, option)}
              >
                {option.toLowerCase()}
                {option === rangeFilter
                  && <Done />
                }
              </MenuItem>
            ))}
          </Menu>
        </Popper>

      </DateIncrementDropDownStyled>
    );
  }
}

export default RangeDropdown;
