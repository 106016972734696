import numbro from 'numbro';
import { rangeValues } from '../constants';
import { getNumberOfDays } from '../helpers/dateRange';
import { uniqBy } from 'lodash';

const getHours = (timerange, date) => {
  switch (timerange) {
    case rangeValues.DAY:
      return 24;
    case rangeValues.WEEK:
      return 168;
    case rangeValues.MONTH: {
      if (date) {
        return 24 * getNumberOfDays(date);
      }
      return 720; // default to 30 days
    }
    default:
      return 720;
  }
};

const formatToCurrency = num => numbro(num).formatCurrency({
  average: true,
  // 3 decimal places if num is a million or more, 2 otherwise
  mantissa: num > 1000000 ? 3 : 2,
}).toUpperCase();

const sumSales = (orders) => {
  const total = orders.reduce((total, order) => total + order.total, 0);
  return total / 100;
};

// These functions assume orders have already
// been filtered to the desired time frame.
const getTotalSalesFormatted = (orders) => {
  const totalSalesUnformatted = sumSales(orders);
  return formatToCurrency(totalSalesUnformatted);
};

const ordersPerHour = (orders, timerange, date) => {
  const hours = getHours(timerange, date);
  return (orders.length / hours).toFixed(2);
};

const getPersonCount = (orders, property) => {
  const missingPeople = orders.filter(order => !order[property]);
  const uniqPeople = uniqBy(orders.filter(order => order[property]), property);

  return (uniqPeople.length + missingPeople.length) || 1;
};

const salesPerPerson = (orders, property) => {
  const personCount = getPersonCount(orders, property);

  const total = sumSales(orders) / personCount;
  return formatToCurrency(total);
};

const salesPerEmployee = (orders) => salesPerPerson(orders, 'employeeName');

const salesPerCustomer = (orders) => salesPerPerson(orders, 'customer');

const salesPerHour = (orders, timerange, date) => {
  const hours = getHours(timerange, date);
  const total = sumSales(orders) / hours;
  return formatToCurrency(total);
};

export default {
  getHours,
  sumSales,
  getTotalSalesFormatted,
  ordersPerHour,
  getPersonCount,
  salesPerPerson,
  salesPerEmployee,
  salesPerCustomer,
  salesPerHour,
  formatToCurrency,
};
