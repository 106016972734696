import { createSelector } from 'reselect';

export const initialState = {
  employeeStats: [],
  itemStats: [],
  error: '',
};

// Action Types
export const FETCH_EMPLOYEE_STATS = 'FETCH_EMPLOYEE_STATS';
export const SET_EMPLOYEE_STATS = 'SET_EMPLOYEE_STATS';
export const FETCH_ITEM_STATS = 'FETCH_ITEM_STATS';
export const SET_ITEM_STATS = 'SET_ITEM_STATS';
export const SET_ERROR = 'SET_ERROR';

export const fetchEmployeeStats = filter => ({
  type: FETCH_EMPLOYEE_STATS,
  filter,
});

export const setEmployeeStats = payload => ({
  type: SET_EMPLOYEE_STATS,
  payload,
});

export const fetchItemStats = filter => ({
  type: FETCH_ITEM_STATS,
  filter,
});

export const setItemStats = payload => ({
  type: SET_ITEM_STATS,
  payload,
});

export const setStatsError = error => ({
  type: SET_ERROR,
  error,
});

// Selectors
export const getStats = state => state.statistics;
export const getEmployeeStats = createSelector(getStats, state => state.employeeStats);
export const getItemStats = createSelector(getStats, state => state.itemStats);

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_STATS:
      return { ...state, employeeStats: action.payload };
    case SET_ITEM_STATS:
      return { ...state, itemStats: action.payload };
    case SET_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
