import { connect } from 'react-redux';
import Statistics from './Statistics';

import { getRawOrders } from '../../../modules/order';
import { getRangeFilter, getCurrentDate } from '../../../modules/filter';
import OrderService from '../../../utils/order.service';

export const mapStateToProps = (state) => {
  const orders = getRawOrders(state);
  const timerange = getRangeFilter(state);
  const date = getCurrentDate(state);
  return {
    orders,
    totalSales: OrderService.getTotalSalesFormatted(orders),
    totalOrders: orders.length,
    ordersPerHour: OrderService.ordersPerHour(orders, timerange, date),
    perEmployee: OrderService.salesPerEmployee(orders),
    perCustomer: OrderService.salesPerCustomer(orders),
    perHour: OrderService.salesPerHour(orders, timerange, date),
  };
};

export default connect(mapStateToProps)(Statistics);
