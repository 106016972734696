import { connect } from 'react-redux';

import { getEmployeeStats } from '../../../modules/statistics';
import { getPieChartData } from '../../../helpers/charts';
import { PieChart } from '../../shared';

export const mapStateToProps = (state) => {
  const employeeStats = getEmployeeStats(state);
  return {
    chartTitle: 'Top Employees',
    seeMoreData: {
      path: '/employees',
      label: 'EMPLOYEES',
    },
    ...getPieChartData(employeeStats),
  };
};

export default connect(mapStateToProps)(PieChart);
