import { connect } from 'react-redux';
import { getRangeFilter, setRangeFilter } from '../../modules/filter';
import { getScreenSize, setUpgradeModalVisibility, getBillingStatus } from '../../modules/core';
import DateIncrementDropdown from './DateIncrementDropdown';

const mapStateToProps = state => ({
  rangeFilter: getRangeFilter(state),
  screenSize: getScreenSize(state),
  billingStatus: getBillingStatus(state),
});

const dispatchProps = {
  select: setRangeFilter,
  setModalState: status => setUpgradeModalVisibility(status),
};

export default connect(mapStateToProps, dispatchProps)(DateIncrementDropdown);
