import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import styles from '../../../styles';
import { renderLabels } from '../../../helpers/charts';
import configureData from './configureData';
import {
  formattedOrderTotal,
  getCurrentMonth,
  getFullYear,
  isDay,
  isWeek,
  isYear,
} from '../../../helpers/dateRange';
import {
  MOBILE,
  DESKTOP,
  TABLET,
  isMobile,
  isDesktop,
} from '../../../utils/environment';

// disable chart animation on first load (remember to import defaults from react chartjs)
// defaults.global.animation = false;

const LineChartStyled = styled.div`
  display: block;
  position: relative;
  height: 250px;
  background-color: ${styles.colors.darkBlue};
  padding-left: 20px;
  padding-right: 20px;
`;

const responsiveGradientPoint = (screenSize) => {
  let point;
  switch (screenSize) {
    case DESKTOP:
      point = 1500;
      break;
    case TABLET:
      point = 650;
      break;
    case MOBILE:
      point = 350;
      break;
    default:
      point = 1500;
  }
  return point;
};


class LineChart extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { currentDate } = this.props;
    if (nextProps.currentDate !== currentDate) {
      return false;
    }
    return true;
  }

  render() {
    const {
      screenSize,
      filter,
      orders,
      currentDate,
    } = this.props;

    const labels = renderLabels(screenSize, filter, currentDate);

    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        displayColors: false,
        backgroundColor: styles.colors.white,
        titleFontFamily: styles.typography.fonts.openSans,
        titleFontColor: styles.colors.lightGray,
        titleFontSize: styles.typography.sizes.smaller,
        _titleAlign: 'center',
        bodyFontFamily: styles.typography.fonts.openSans,
        bodyFontColor: styles.colors.black,
        bodyFontSize: styles.typography.sizes.small,
        bodyFontStyle: 'bold',
        _bodyAlign: 'center',
        xPadding: 15,
        yPadding: 10,
        callbacks: {
          title: (items) => {
            const label = labels[items[0].index].replace(/[a-z]/g, '');
            if (isDay(filter)) {
              return `${label}:00 - ${label}:59`;
            }
            if (isYear(filter)) {
              return `${labels[items[0].index]}, ${getFullYear(currentDate)}`.toUpperCase();
            }
            return `${getCurrentMonth(currentDate)} ${label}, ${getFullYear(currentDate)}`.toUpperCase();
          },
          label: data => formattedOrderTotal(data.yLabel),
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 10,
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            callback: (tick, index) => {
              if (!isWeek(filter) && !isDesktop(screenSize)) {
                if (tick % 2 === 0 || (index + 1) % 2 === 0) {
                  return '';
                }
              }
              return tick;
            },
            fontColor: styles.colors.white,
            fontSize: isMobile(screenSize)
              ? styles.typography.sizes.small
              : styles.typography.sizes.medium,
            fontStyle: isMobile(screenSize)
              ? styles.typography.weights.regular
              : styles.typography.weights.light,
          },
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: true,
            color: styles.colors.gray,
            drawBorder: false,
            zeroLineColor: styles.colors.gray,
          },
          ticks: {
            display: false,
            min: 0,
            maxTicksLimit: 6,
          },
        }],
      },
      maintainAspectRatio: false,
    };

    const data = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, responsiveGradientPoint(screenSize), 0);
      gradient.addColorStop(0, styles.colors.gradient1);
      gradient.addColorStop(1, styles.colors.gradient2);
      return {
        labels,
        datasets: [
          {
            backgroundColor: styles.colors.white,
            fill: false,
            lineTension: 0,
            borderColor: gradient,
            borderWidth: 2,
            pointBackgroundColor: styles.colors.white,
            pointBorderColor: styles.colors.white,
            pointRadius: 2,
            data: configureData(orders, filter, labels),
          },
        ],
      };
    };

    return (
      <LineChartStyled>
        <Line data={data} options={options} key={Math.random()} />
      </LineChartStyled>
    );
  }
}


LineChart.propTypes = {
  screenSize: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  orders: PropTypes.instanceOf(Array),
};

LineChart.defaultProps = {
  orders: [],
};

export default LineChart;
