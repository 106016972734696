import { connect } from 'react-redux';
import { getOrderFetchingStatus } from '../../../modules/order';
import Loader from './Loader';
import { getSalesTrendsFetchingStatus } from '../../../modules/salesTrends';

const mapStateToProps = state => ({
  isOrdersFetching: getOrderFetchingStatus(state),
  isSalesTrendsFetching: getSalesTrendsFetchingStatus(state),
});

export default connect(mapStateToProps)(Loader);
