import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../styles';
import { StatLabel, StatValue } from '../index';
import OrderService from '../../../utils/order.service';

/* eslint react/jsx-one-expression-per-line: 0 */

const Container = styled.section`
  background-color: ${styles.colors.lighterGray};
  display: grid;
  flex-wrap: wrap;
  &.tablet, &.desktop {
    grid-template-columns: [start] repeat(6, 1fr) [end];
    height: 220px;
    .totalSales, .overallSales, .salesPerHour {
      grid-column: 2 / 4;
      margin-left: -38px;
    }
    .itemsSold, .itemsPerOrder, .itemsPerHour {
      grid-column: 4 / 7;
      padding-left: 52px;
    }
  }
  &.mobile {
    grid-template-columns: [start] repeat(3, 1fr) [end];
      .totalSales, .overallSales, .salesPerHour, .itemsSold, .itemsPerOrder, .itemsPerHour {
        grid-column: 2 / end;
        margin-left: -55px;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Statistics = ({ statsData, screenSize }) => (
  <Container className={`stats ${screenSize}`}>
    <Wrapper className="totalSales">
      <StatLabel>Total Sales</StatLabel>
      <StatValue>{OrderService.formatToCurrency(statsData.totalSales / 100)}</StatValue>
    </Wrapper>
    <Wrapper className="itemsSold">
      <StatLabel>Items Sold</StatLabel>
      <StatValue>{statsData.totalItemCount}</StatValue>
    </Wrapper>
    <Wrapper className="overallSales">
      <StatLabel>Overall Sales<br />Percentage</StatLabel>
      <StatValue>{statsData.overallSales}%</StatValue>
    </Wrapper>
    <Wrapper className="itemsPerOrder">
      <StatLabel>Items per<br />Order</StatLabel>
      <StatValue>{statsData.itemsPerOrder}</StatValue>
    </Wrapper>
    <Wrapper className="salesPerHour">
      <StatLabel>Sales per<br />Hour</StatLabel>
      <StatValue>{OrderService.formatToCurrency(statsData.salesPerHour)}</StatValue>
    </Wrapper>
    <Wrapper className="itemsPerHour">
      <StatLabel>Items per Hour</StatLabel>
      <StatValue>{statsData.itemsPerHour}</StatValue>
    </Wrapper>
  </Container>
);

Statistics.propTypes = {
  screenSize: PropTypes.string.isRequired,
  statsData: PropTypes.instanceOf(Object).isRequired,
};

export default Statistics;
