import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles';
import { pieChartColors, tabValues } from '../../constants';
import { getFilter } from '../../helpers/dateRange';
import { getPieChartData } from '../../helpers/charts';
import Mixpanel from '../../utils/mixpanel';
import { Title, HeaderPanel, CollapsiblePanel } from '../shared';

const EmployeesStyled = styled.section`
  grid-row: header / end;
  color: ${styles.colors.white};
  background-color: ${styles.colors.black};
  &.desktop {
    grid-column: 4 / 10;
  }
  &.tablet {
    grid-column: start / end;
    padding: 0 50px 0 50px;

  } &.mobile {
    grid-column: start / end;
    padding: 8px;
  }
`;

class Employees extends React.Component {
  state = {
    selectedRowId: 0,
  };

  componentDidMount() {
    const { setTab, fetchEmployeeStatsWithFilter } = this.props;
    setTab(tabValues.EMPLOYEES);

    const filter = getFilter(this.props);
    fetchEmployeeStatsWithFilter(filter);
    Mixpanel.track('Navigation: Employees View', {});
  }

  componentDidUpdate(prevProps) {
    const {
      currentDate,
      rangeFilter,
      fetchEmployeeStatsWithFilter,
    } = this.props;

    if (currentDate !== prevProps.currentDate || rangeFilter !== prevProps.rangeFilter) {
      const filter = getFilter(this.props);
      fetchEmployeeStatsWithFilter(filter);
    }
  }

  setSelectedRowId = id => this.setState({ selectedRowId: id });

  render() {
    const {
      screenSize, listData, rangeFilter, currentDate,
    } = this.props;
    const { selectedRowId } = this.state;
    const maxValue = listData[0] ? listData[0].totalSales : 0;

    return (
      <EmployeesStyled className={`employees ${screenSize}`}>
        <Title screenSize={screenSize}>EMPLOYEES</Title>
        <HeaderPanel screenSize={screenSize} breakDown="items" />
        {
          listData.map((employee, i) => (
            <CollapsiblePanel
              key={employee.name}
              rank={i + 1}
              itemName={employee.name}
              itemValue={employee.totalSales}
              maxValue={maxValue}
              barColor={pieChartColors[i]}
              barChartData={employee.bargraphData}
              pieChartData={getPieChartData(employee.items)}
              screenSize={screenSize}
              rangeFilter={rangeFilter}
              currentDate={currentDate}
              setSelectedRowId={this.setSelectedRowId}
              selectedRowId={selectedRowId}
              statsData={employee}
              mixpanelSectionName="Employees"
              mixpanelFirstSectionEvent="Sales Details"
              mixpanelSecondSectionEvent="Sales/Time Details"
              mixpanelThirdSectionEvent="Items Details"
            />
          ))
        }
      </EmployeesStyled>
    );
  }
}

Employees.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  fetchEmployeeStatsWithFilter: PropTypes.func.isRequired,
  listData: PropTypes.instanceOf(Object).isRequired,
  rangeFilter: PropTypes.string.isRequired,
  screenSize: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default Employees;
