import React, { Component } from 'react';
import { IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import typography from '../../../styles/typography';
import colors from '../../../styles/colors';
import { GradientButton } from '..';


const getLeftMargin = width => (window.innerWidth - width) / 2;

const ModalWrapper = styled.div`
    position: absolute;
    background-color: white;
    border-radius: 5px;
    top: 126px;
    &.desktop {
       left: ${props => getLeftMargin(props.desktopWidth)}px;
       width: ${props => props.desktopWidth}px;
    }
    &.tablet {
      margin: 0 28px;
      .content-wrapper {
        margin: 32px 120px 40px;
      }
    }
    &.mobile {
      margin: 0 8px;
      .content-wrapper {
        margin: 32px 72px 40px;
      }
    }
`;

const ModalContentStyled = styled.div`
  position: relative;
  align-items: center;
  margin: 32px 120px 40px;
  text-align: center;
`;

const IconButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

const IconButtonStyled = styled(IconButton)`
  padding-top: 16px;
  padding-right: 16px;
`;

const BodyStyled = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: ${typography.sizes.medium}; 
`;

const ModalTitleStyled = styled.div`
  text-align: center;
  font-family: ${typography.fonts.openSans};
  font-weight: ${typography.weights.semibold};
  font-size: ${typography.sizes.hero}px;
  color: ${colors.lightBlue}
`;

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.black};
`;

class ReusableModal extends Component {
  closeModal = () => {
    this.props.onClose();
  };

  render() {
    const {
      modalVisibility,
      screenSize,
      desktopWidth,
      children,
      modalTitle,
      buttonText,
      href,
      disableButton
    } = this.props;

    return (
      <div>
        <Modal
          open={modalVisibility}
          onClose={this.closeModal}
        >
          <ModalWrapper className={screenSize} desktopWidth={desktopWidth}>
            <IconButtonWrapper>
              <IconButtonStyled onClick={this.closeModal}>
                <CloseIconStyled fontSize="large" />
              </IconButtonStyled>
            </IconButtonWrapper>

            <ModalContentStyled className="content-wrapper">
              <ModalTitleStyled>{modalTitle}</ModalTitleStyled>
              <BodyStyled>{children}</BodyStyled>
              { disableButton ? null : <GradientButton href={href} buttonText={buttonText} /> }
            </ModalContentStyled>
          </ModalWrapper>
        </Modal>
      </div>
    );
  }
}

ReusableModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  screenSize: PropTypes.string.isRequired,
  desktopWidth: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  disableButton: PropTypes.bool,
};

ReusableModal.defaultProps = {
  onClose: () => {},
  disableButton: false,
  href: '',
};

export default ReusableModal;
