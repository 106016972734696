import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getScreenSize, getUpgradeModalVisibility } from '../../../modules/core';
import UpgradeModal from './UpgradeModal';


export const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  isVisible: getUpgradeModalVisibility(state),
});

export default withRouter(connect(mapStateToProps)(UpgradeModal));
